import React, { useState } from 'react';

import {CheckCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'

const SuccessMessage = ({ title, text, className = null}) => {
    const [isDismissed, setIsDismissed] = useState(false)

    return (
        !isDismissed ? <div className={`cursor-pointer rounded-md bg-white border border-green p-3 ${className}`} onClick={() => setIsDismissed(true)}>
            <div className="relative flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-xl font-medium text-green leading-none mb-1">{title}</h3>
                    <div className="mt-1 text-base">
                        <p>{text}</p>
                    </div>
                </div>
                <XMarkIcon className='absolute right-0 top-0 w-5 h-5' />
            </div>
        </div> : null
    )
}

export default SuccessMessage;
