import React, {useContext, useRef, useState} from 'react';

import { CurrentUser } from '../../App';
import requestClient from '../../utils/requestClient';
import occupationActivities from '../../utils/occupationActivities';

import {loadCsrfToken} from '../../utils/api';
import FormInput from '../../components/Elements/Form/FormInput';
import FormSelect from '../../components/Elements/Form/FormSelect';
import FormCheckbox from '../../components/Elements/Form/FormCheckbox';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import ErrorMessage from '../../components/Elements/ErrorMessage';

const Profile = () => {
    const user = useContext(CurrentUser)
    
    const firstNameRef = useRef(null)
    const lastNameRef = useRef(null)
    const occupationActivityRef = useRef(null)
    const emailOptInRef = useRef(null)
    const dairyFreeRef = useRef(null)
    const meatFreeRef = useRef(null)
    const glutenFreeRef = useRef(null)
    const veganRef = useRef(null)
    const passwordRef = useRef(null)
    const passwordConfirmRef = useRef(null)

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(null);

    async function handleSubmit(event) {
        event.preventDefault()

        setSuccess(false)
        setErrors(null)

        if( passwordRef.current.value  && passwordRef.current.value != passwordConfirmRef.current.value){
            setErrors(['Passwords must match'])
            return
        }

        await requestClient().patch('/api/v2/user/', {
            first_name: firstNameRef.current.value,
            last_name: lastNameRef.current.value,
            occupational_activity: occupationActivityRef.current.value,
            email_opt_in: emailOptInRef.current.checked,
            dairy_free: dairyFreeRef.current.checked,
            meat_free: meatFreeRef.current.checked,
            gluten_free: glutenFreeRef.current.checked,
            vegan: veganRef.current.checked,
            password: passwordRef.current.value
        }).then(response => {
            if(response.status === 200){
                setSuccess(true)
            }
        })
        .catch(error => {
            setErrors(error.response.data.error)
        })
    }

    return (user ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between border-b pb-8 mb-8'>
                        <h1>Account</h1>
                    </div>
                    { success ? <SuccessMessage title="Profile updated"  text="Your profile has been updated" className="mb-4 sticky top-2" /> : null }
                    { errors ? <ErrorMessage title="Profile not updated"  errors={errors} className="mb-4 sticky top-2" /> : null}
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-8 lg:gap-12 lg:grid-cols-12 mb-8'>
                            <div className='grid gap-6 lg:grid-cols-2 lg:col-span-8'>
                                <FormInput type="text" name="first_name" label="First Name:" value={user.user.first_name} inputRef={firstNameRef} />
                                <FormInput type="text" name="last_name" label="Last Name:" value={user.user.last_name} inputRef={lastNameRef} />
                                {/* <FormSelect name="location" label="Location:" options={countries} /> */}
                                <FormSelect name="occupational_activity" label="Occupational Activity:" value={user.user.occupational_activity} options={occupationActivities} inputRef={occupationActivityRef} />
                                <FormCheckbox name="email_opt_in" label="Opt in to email notifications" value={user.user.email_opt_in} className="lg:col-span-2" inputRef={emailOptInRef} />
                                <FormInput type="password" name="new_password" label="New Password:" inputRef={passwordRef} />
                                <FormInput type="password" name="confirm_password" label="Confirm Password:" inputRef={passwordConfirmRef} />
                            </div>
                            <div className={`lg:col-span-4 ${user.user.user_type !== 'employee' ? 'hidden' : ''}`}>
                                <div className='col-span-2'>
                                    <span className='block font-medium mb-4'>Your Dietary Requirements:</span>
                                    <div className='flex flex-col gap-4'>
                                        <FormCheckbox name="dairy_free" label="Dairy Free" value={user.user.dairy_free} inputRef={dairyFreeRef} />
                                        <FormCheckbox name="meat_free" label="Meat Free" value={user.user.meat_free} inputRef={meatFreeRef} />
                                        <FormCheckbox name="gluten_free" label="Gluten Free" value={user.user.gluten_free} inputRef={glutenFreeRef} />
                                        <FormCheckbox name="vegan" label="Vegan" value={user.user.vegan} inputRef={veganRef} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-purple block lg:inline-block lg:min-w-[250px]">UPDATE</button>
                    </form>
                </div>
            </section>
        </main> : null);
};

export default Profile;
