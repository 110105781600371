import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import {resourceMapper} from '../../utils/resourceMapper';
import Image from '../../components/Elements/Image';
import Video from '../../components/Elements/Video';
import ArticleBreadcrumb from '../../components/Elements/ArticleBreadcrumb';
import RelatedArticles from '../../components/Sections/RelatedArticles';

const Sentiment = () => {
  const type = 'sentiment'
  const { id } = useParams();

  const resource = resourceMapper(type)

  const [article, setArticle] = useState(null);

  useEffect(() => {
    async function getArticle() {
      const response = await requestClient().get(`/api/v2/${type}/${id}`)
      setArticle(response.data.data);
    }
    getArticle();
  }, []);

  return (article ?
    <main>
      <article className='bg-white'>
        <div className='container py-8 lg:py-12'>
          <ArticleBreadcrumb article={article.content} />

          <div className='max-w-[768px] mx-auto'>
            <h1 className='mb-8'>{article.content.title}</h1>
                {article.content.video_file ? (
                <Video src={article.content.video_file} className="aspect-video w-full rounded-xl lg:rounded-2xl" />
                ) : (<Image src={article.content.image} className="aspect-video w-full rounded-xl lg:rounded-2xl" />)}

                {article.content.pdf_document ? (<a href={article.content.pdf_document} target='_blank' className="btn btn-purple mt-4 block lg:inline-block">Download Content</a>) : null}

                <section>
                {'description' in article ? (
                    <div className="mt-8 prose whitespace-pre-line">
                    <p>{article.content.description}</p>
                    </div>
                ) : null}

                {'content' in article.content ? (
                    <div className="mt-8 prose whitespace-pre-line">
                    <p>{article.content.content}</p>
                    </div>
                ) : null}
                </section>
            </div>
        </div>
      </article>
      <RelatedArticles article={article} />
    </main>
    : null);
};

export default Sentiment;