import React, {useRef, useState} from 'react';
import { Link, useParams } from 'react-router-dom'; 
import requestClient from '../../utils/requestClient';

import FormInput from '../../components/Elements/Form/FormInput';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import ErrorMessage from '../../components/Elements/ErrorMessage';

const NewPassword = () => {
    const { id, token } = useParams();

    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(null);

    const passwordRef = useRef(null)
    const passwordConfirmRef = useRef(null)

    let number = /([0-9])/;
    // let alphabets = /([a-zA-Z])/;
    // let specialCharacters = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

    async function handleSubmit(event) {
        setSuccess(false)
        setErrors(null)

        event.preventDefault()

        if( passwordRef.current.value != passwordConfirmRef.current.value){
            setErrors(['Passwords must match'])
            return
        }

        if( !passwordRef.current.value.match(number)){
            setErrors(["Weak Your password must contain at least 8 characters and can't be entirely numeric."])
            return
        }

        await requestClient().post(`/api/v2/password-reset-confirm/${id}/${token}/`, {
            new_password: passwordRef.current.value
        }).then(response => {
            setSuccess(true)
        }).catch(error => {
            setErrors(error.response.data.error)
        })
    }

    return (
        <main>
            <section>
                 <div className='container py-8 lg:py-12 lg:max-w-[500px]'>
                    <h1 className="text-center h2 mb-8">
                        New Password
                    </h1>
                    { success ? <SuccessMessage title="Password Reset Success"  text="Your password has been successfully reset." className="mb-4" /> : null }
                    { errors ? <ErrorMessage title="Something is not right"  errors={errors} className="mb-4" /> : null}
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-5'>
                            <FormInput type="password" name="new_password" label="New Password:" inputRef={passwordRef} props={{'required': 'required'}} />
                            <FormInput type="password" name="confirm_password" label="Confirm Password:" inputRef={passwordConfirmRef} props={{'required': 'required'}} />
                            <p className='text-xs whitespace-pre-line'>
                                Your password must contain at least 8 characters.<br/>
                                Your password can't be entirely numeric.
                            </p>
                            <button className="btn btn-purple block">RESET</button>
                            <Link to="/login" className="btn btn-invert block">Back to Login</Link>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
};

export default NewPassword;
