import React, { forwardRef, useState, useImperativeHandle } from 'react';

const Modal = forwardRef((props, ref) => {
    const [open, setOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleModal: () => {
            setOpen(!open)
        }
    }));

    return (
        <>
            <button className="btn btn-purple block w-full lg:w-auto" onClick={() => setOpen(true)}>{props.buttonText}</button>

            {open ?
                <div className="fixed top-0 left-0 w-full h-full" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="fixed inset-0 z-20 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setOpen(false)}></div>
                    <div className="flex min-h-full items-end justify-center p-4 text-center items-center sm:p-0">
                        <div className="z-30 relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div>
                                {props.slot}
                            </div>
                        </div>
                    </div>
                </div>
                : null}
        </>
    );
});

export default Modal;