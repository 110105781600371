import React from 'react';

const MindIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.303711 15.1527C0.303711 6.78407 7.08779 0 15.4564 0C24.335 0 32.1217 5.92573 34.4879 14.4831L37.3538 23.4354H30.4831V40H19.1483C8.74058 40 0.303711 31.5629 0.303711 21.1554V15.1527Z" fill="white"/>
        <path d="M25.5919 15.3298C25.5919 10.3265 21.6196 6.05838 16.4387 5.74464C10.7619 5.40089 6.0293 9.75421 6.0293 15.153V15.3298C6.0293 16.6445 7.13498 17.7104 8.49898 17.7104H23.122C24.486 17.7104 25.5919 16.6445 25.5919 15.3298Z" fill="#59007A"/>
    </svg>
);

export default MindIconResults;
