import React, { useState, useEffect, useRef } from 'react';
import { MagnifierIcon } from '../../components/Icons';

import requestClient from '../../utils/requestClient';
import Pagination from '../../components/Elements/Pagination';
import FormInput from '../../components/Elements/Form/FormInput';
import FormFile from '../../components/Elements/Form/FormFile';
import Modal from '../../components/Elements/Modal';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import ErrorMessage from '../../components/Elements/ErrorMessage';

const Employees = ({ }) => {
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(null);
    const [data, setData] = useState(null);
    const [company, setCompany] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)
    const [term, setTerm] = useState('')
    const modalRef = useRef(null)

    const getEmployees = async () => {
        const response = await requestClient().get(`/api/v2/employer/employees/?page=${currentPage}&term=${term}`)
        setData(response.data);
    }

    const toggleModal = () => {
        modalRef.current.toggleModal()
    }

    useEffect(() => {
        async function getData() {
            await getEmployees()
        }
        getData();
    }, []);

    useEffect(() => {
        async function paginate() {
            await getEmployees()
        }
        paginate()
    }, [currentPage]);

    useEffect(() => {
        async function search() {
            await getEmployees()
        }
        search()
    }, [term]);

    useEffect(() => {
        async function getCompany() {
            const response = await requestClient().get('/api/v2/employer/company/')

            setCompany(response.data.data);
        }
        getCompany();
    }, []);

    const inviteEmployee = async (id, email) => {
        setSuccess(false)
        setErrors(null)

        const response = await requestClient().post(`/api/v2/employer/employees/invite`, {
            'ids': [id]
        })
            .then(response => {
                setSuccess(`${email} has been invited`)
            })
            .catch(error => {
                setErrors(error.response.data.error)
            })
    };

    const inviteEmployees = async () => {
        setSuccess(false)
        setErrors(null)

        const response = await requestClient().post(`/api/v2/employer/employees/invite`, {
            'ids': []
        })
            .then(response => {
                setSuccess(`Invites have been re-sent to members`)
            })
            .catch(error => {
                setErrors(error.response.data.error)
            })
    };

    const deleteEmployee = async (id) => {
        const confirmation = window.confirm(`Are you sure you want to erase employee with ID: ${id}?`);
        if (confirmation) {
            const response = await requestClient().delete(`/api/v2/employer/employees/${id}`)
            await getEmployees()
        }
    };

    const addEmployee = async (event) => {
        setSuccess(false)
        setErrors(null)
        event.preventDefault()

        const email = event.target[0].value

        await requestClient().post(`/api/v2/employer/employees/`, {
            email: email
        }).then(response => {
            setSuccess(`${email} has been invited`)
            toggleModal()
            getEmployees()

        }).catch(error => {
            setErrors(error.response.data.error)
            toggleModal()
        })
    };

    const uploadEmployee = async (event) => {
        setSuccess(false)
        setErrors(null)
        event.preventDefault()

        let formData = new FormData();
        formData.append("csv", event.target[0].files[0]);

        await requestClient().post(`/api/v2/employer/employees/invite/csv`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
        ).then(response => {
            setSuccess(`Your invites are being processed`)
            toggleModal()
        }).catch(error => {
            setErrors(error.response.data.error)
            toggleModal()
        })
    };

    const Form = () => {
        return (
            <div>
                <h3 className='mb-6'>Add Team Members</h3>
                <form onSubmit={addEmployee} className='grid gap-2 mb-6'>
                    <FormInput label="Add a team member by email" type="email" />
                    <button className='btn btn-invert ml-auto'>ADD</button>
                </form>
                <form onSubmit={uploadEmployee} className='grid gap-2 mb-6'>
                    <FormFile id="file" name="csv" label="Import Employees from a .csv File" props={{ 'required': 'required' }} />
                    <div className='flex justify-between'>
                        <a className="block font-bold mb-2" href="/assets/employee-upload-example.csv" download={true}>Example CSV</a>
                        <button className='btn btn-invert '>ADD</button>
                    </div>
                </form>
                <p className='text-sm'>*<span className='font-bold'>{company.remaining_seats}</span> places remain in your plan. Please
                    <a href='mailto:hello@myopti.me' className='font-bold'>contact the OptiMe Team</a> to upgrade your subscription if you require more.</p>
            </div>
        )
    }

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='border-b pb-8 flex lg:items-center lg:justify-between mb-8 gap-4 flex-col lg:flex-row'>
                        <h1>Employees</h1>
                        <div className='rounded-full border flex relative overflow-hidden pl-[40px] w-full flex lg:w-auto'>
                            <MagnifierIcon className="absolute left-3 top-1/2 mt-[-10px]" />
                            <input type="text" placeholder="Search" className="border-none w-full px-1 py-3 focus:ring-0 min-w-[200px]" onKeyUp={(event) => { setTerm(event.target.value) }} />
                        </div>
                    </div>
                    {success ? <SuccessMessage title="Success" text={success} className="mb-4 sticky top-2" /> : null}
                    {errors ? <ErrorMessage title="Something is not right" errors={errors} className="mb-4 sticky top-2" /> : null}
                    <div className='flex gap-4 flex-col lg:flex-row lg:justify-end mb-4'>
                        <button className='btn btn-invert w-full lg:w-auto' onClick={() => inviteEmployees()}>Resend Invites</button>
                        <Modal ref={modalRef} buttonText="ADD USER" slot={(<Form />)} />
                    </div>
                    <div className='overflow-scroll mb-8'>
                        <table className='table-auto w-full text-left'>
                            <thead className='text-sm text-purple uppercase'>
                                <tr>
                                    <th className='p-3'>Email</th>
                                    <th className='p-3'>Name</th>
                                    <th className='p-3'>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.data.map((employee, index) => (
                                    <tr key={employee.id}>
                                        <td className={`p-3 px-3 ${index % 2 ? '' : 'bg-yellow-light'}`}>{employee.email}</td>
                                        <td className={`p-3 ${index % 2 ? '' : 'bg-yellow-light'}`}>{employee.first_name} {employee.last_name}</td>
                                        <td className={`p-3 ${index % 2 ? '' : 'bg-yellow-light'}`}>
                                            <span className='flex gap-2 justify-end flex-col lg:flex-row'>
                                                <button className="flex-none whitespace-nowrap btn-sm btn-invert w-auto" onClick={() => inviteEmployee(employee.id, employee.email)}>
                                                    RE-INVITE
                                                </button>
                                                <button className="flex-none whitespace-nowrap btn-sm btn-red" onClick={() => deleteEmployee(employee.id)}>
                                                    DELETE
                                                </button>
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination currentPage={currentPage} data={data.data} pagination={data.meta.paginator} callback={setCurrentPage} />
                </div>
            </section>
        </main>
        : null);
}

export default Employees;
