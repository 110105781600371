import React from 'react';

const OptimeLogoColor = ({ className, style }) => (
    <svg className={className} style={style} width="146" height="40" viewBox="0 0 146 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.6702 1.55205C22.2895 0.62354 21.3844 0.0238768 20.381 0.00396262C16.1732 -0.0796345 11.9048 1.15835 8.23835 3.8243C-0.0193462 9.8286 -2.33121 20.9696 2.49666 29.6764C2.97971 30.5476 3.9373 31.0482 4.92886 30.9531C8.60703 30.6004 12.2421 29.2903 15.447 26.9599C23.5842 21.0431 26.3245 10.464 22.6702 1.55205Z" fill="#59017A"/>
        <path d="M33.9539 22.0114C34.8002 23.1754 35.9193 24.0231 37.1596 24.5317C38.2923 24.9961 39.5738 24.2789 39.7616 23.0692C40.3147 19.5088 39.9113 15.7997 38.4858 12.3595C38.0195 11.2338 36.6133 10.823 35.6256 11.5367C35.6193 11.5412 35.613 11.5458 35.6067 11.5504C32.2615 13.9827 31.5216 18.6662 33.9539 22.0114Z" fill="#00A7CF"/>
        <path d="M18.1728 28.3112C15.1941 30.4771 13.3698 33.6401 12.8241 37.0095C12.673 37.9417 13.2409 38.847 14.144 39.1228C19.9447 40.8944 26.4782 40.0165 31.7616 36.1748C33.3208 35.041 34.6673 33.7238 35.7943 32.2746C36.3725 31.5309 36.3106 30.4668 35.6537 29.7918C31.0809 25.092 23.6314 24.3421 18.1728 28.3112Z" fill="#96FB1E"/>
        <path d="M73.0117 35.3461V15.263H76.7513V16.4541C77.9147 15.5309 79.2812 15.0691 80.8511 15.0691C82.2175 15.051 83.455 15.3738 84.5629 16.0387C85.671 16.7035 86.5527 17.5947 87.2084 18.7118C87.8638 19.8293 88.1918 21.0898 88.1918 22.4929C88.1918 23.878 87.8593 25.1293 87.1946 26.2464C86.5297 27.3639 85.6338 28.2504 84.5077 28.9056C83.381 29.5614 82.1252 29.8891 80.7404 29.8891C80.0014 29.8891 79.3042 29.7874 78.6489 29.5843C77.9932 29.3814 77.3789 29.0953 76.8068 28.7257V35.3461H73.0117ZM80.2416 26.6758C81.0354 26.6758 81.7514 26.4958 82.3885 26.1357C83.0255 25.7755 83.5285 25.2726 83.8982 24.6259C84.2674 23.9798 84.4521 23.2685 84.4521 22.4929C84.4521 21.6991 84.2674 20.988 83.8982 20.36C83.5285 19.7324 83.0255 19.2337 82.3885 18.8642C81.7514 18.4949 81.0354 18.3101 80.2416 18.3101C79.5395 18.3101 78.8934 18.4256 78.3026 18.6564C77.7112 18.8875 77.2128 19.2337 76.8068 19.6951V25.3184C77.2128 25.7435 77.7161 26.0759 78.3165 26.3157C78.9164 26.5559 79.5582 26.6758 80.2416 26.6758Z" fill="#59017A"/>
        <path d="M96.5938 29.9174C95.0054 29.9174 93.8004 29.5529 92.9788 28.8233C92.1569 28.0939 91.7461 27.0274 91.7461 25.6238V18.4492H88.7822V15.2636H91.7461V11.5795L95.5134 10.7207V15.2636H99.6685V18.4492H95.5134V24.8481C95.5134 25.5129 95.6658 25.9886 95.9705 26.2747C96.2752 26.5612 96.7876 26.704 97.5079 26.704C97.8957 26.704 98.242 26.6812 98.5467 26.6348C98.8515 26.5889 99.1881 26.5101 99.5577 26.3994V29.5019C99.17 29.6312 98.6895 29.7326 98.1174 29.8066C97.5447 29.8801 97.037 29.9174 96.5938 29.9174Z" fill="#59017A"/>
        <path d="M103.921 13.546C103.329 13.546 102.822 13.334 102.397 12.9088C101.972 12.4843 101.76 11.967 101.76 11.3577C101.76 10.7668 101.972 10.2635 102.397 9.84792C102.822 9.43238 103.329 9.22461 103.921 9.22461C104.53 9.22461 105.042 9.43238 105.458 9.84792C105.873 10.2635 106.081 10.7668 106.081 11.3577C106.081 11.967 105.873 12.4843 105.458 12.9088C105.042 13.334 104.53 13.546 103.921 13.546ZM102.037 15.2634H105.832V29.6956H102.037V15.2634Z" fill="#59017A"/>
        <path d="M108.405 29.6953V10.3047H113.585L118.876 20.7756L124.195 10.3047H129.319V29.6953H125.552V15.4294L118.821 28.3102L112.117 15.3739V29.6953H108.405Z" fill="#59017A"/>
        <path d="M138.947 29.9721C137.507 29.9721 136.2 29.6354 135.028 28.9611C133.855 28.2871 132.927 27.3917 132.244 26.274C131.56 25.1569 131.219 23.8966 131.219 22.4929C131.219 21.1078 131.546 19.8475 132.202 18.7118C132.857 17.576 133.749 16.6757 134.875 16.0109C136.002 15.3461 137.248 15.0137 138.615 15.0137C140 15.0137 141.233 15.3508 142.313 16.0248C143.393 16.6991 144.243 17.6176 144.861 18.781C145.48 19.9444 145.79 21.2741 145.79 22.7699V23.7672H135.097C135.263 24.3398 135.54 24.8569 135.928 25.3184C136.316 25.7802 136.787 26.1355 137.341 26.3849C137.895 26.6342 138.495 26.7588 139.141 26.7588C139.769 26.7588 140.36 26.6619 140.914 26.468C141.468 26.274 141.939 25.9927 142.327 25.623L144.82 27.8945C143.933 28.6148 143.024 29.1411 142.091 29.4735C141.159 29.806 140.111 29.9721 138.947 29.9721ZM135.042 21.0525H142.078C141.93 20.4803 141.685 19.9769 141.343 19.5427C141.001 19.1091 140.591 18.7719 140.111 18.5317C139.63 18.292 139.113 18.1715 138.559 18.1715C137.968 18.1715 137.433 18.2872 136.953 18.5178C136.472 18.749 136.071 19.0814 135.748 19.515C135.425 19.9492 135.189 20.4617 135.042 21.0525Z" fill="#59017A"/>
        <path d="M60.4128 30.0001C58.9724 30.0001 57.6288 29.746 56.3822 29.2382C55.1356 28.7305 54.046 28.0194 53.1135 27.1053C52.1807 26.1912 51.4516 25.1294 50.9252 23.9197C50.399 22.7104 50.1357 21.4037 50.1357 20C50.1357 18.5969 50.399 17.2901 50.9252 16.0803C51.4516 14.871 52.1807 13.8088 53.1135 12.8947C54.046 11.9806 55.1356 11.2699 56.3822 10.7619C57.6288 10.2541 58.9724 10 60.4128 10C61.8345 10 63.1689 10.2541 64.4155 10.7619C65.662 11.2699 66.7515 11.9806 67.6843 12.8947C68.6165 13.8088 69.3414 14.871 69.8586 16.0803C70.3754 17.2901 70.6343 18.5969 70.6343 20C70.6343 21.4037 70.3754 22.7104 69.8586 23.9197C69.3414 25.1294 68.6165 26.1912 67.6843 27.1053C66.7515 28.0194 65.662 28.7305 64.4155 29.2382C63.1689 29.746 61.8345 30.0001 60.4128 30.0001ZM60.4128 26.3158C61.2806 26.3158 62.084 26.1544 62.8226 25.8311C63.5612 25.5082 64.2078 25.0602 64.7618 24.4875C65.3156 23.9154 65.7495 23.2457 66.0637 22.4793C66.3774 21.7131 66.5345 20.8865 66.5345 20C66.5345 19.0768 66.3774 18.241 66.0637 17.4931C65.7495 16.7452 65.3156 16.0851 64.7618 15.5125C64.2078 14.9402 63.5612 14.4923 62.8226 14.169C62.084 13.8461 61.2896 13.6842 60.4404 13.6842C59.554 13.6842 58.7368 13.8461 57.989 14.169C57.2409 14.4923 56.5899 14.9402 56.036 15.5125C55.4819 16.0851 55.0477 16.7452 54.734 17.4931C54.4198 18.241 54.2632 19.0582 54.2632 19.9447C54.2632 20.8683 54.4198 21.7131 54.734 22.4793C55.0477 23.2457 55.4819 23.9154 56.036 24.4875C56.5899 25.0602 57.2409 25.5082 57.989 25.8311C58.7368 26.1544 59.5444 26.3158 60.4128 26.3158Z" fill="#59017A"/>
    </svg>
);

export default OptimeLogoColor;
