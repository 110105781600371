import React from 'react';

const Image = ({ src, alt='', className = null }) => {
    const assetDomain = process.env.REACT_APP_ASSET_DOMAIN 

    return (
        <img src={src.includes('http') ? src : assetDomain + src} alt={alt} className={className} />
    );
};

export default Image;
