import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import {resourceMapper} from '../../utils/resourceMapper';
import FavouriteButton from '../../components/Elements/FavouriteButton';
import RatingButton from '../../components/Elements/RatingButton';
import ArticleBreadcrumb from '../../components/Elements/ArticleBreadcrumb';
import RelatedArticles from '../../components/Sections/RelatedArticles';

const Newsletter = () => {
  const type = 'newsletter'
  const { id } = useParams();

  const resource = resourceMapper(type)

  const [article, setArticle] = useState([]);

  useEffect(() => {
    async function getArticle() {
      const response = await requestClient().get(`/api/v2/${type}/${id}`)
      setArticle(response.data.data);
    }
    getArticle();
  }, []);

  return (article.content ?
    <main>
      <article className='bg-white'>
        <div className='container py-8 lg:py-12'>
          <ArticleBreadcrumb article={article.content} />

          <Link to={`/resources?type=${article.content.content_type}`} className="flex items-center mb-8 lg:hidden">← RESOURCES</Link>

          <div className='max-w-[768px] mx-auto'>
            <section>
              <div className="prose" dangerouslySetInnerHTML={{ __html: article.content.newsletter_item.html_content }}></div>
            </section>

            {resource.rating || resource.favourite ?
              <div className="mt-8 py-4 border-t border-b flex items-center justify-between">
                {resource.rating ? <RatingButton article={article.content} /> : null}
                {resource.favourite ? <FavouriteButton article={article.content} favourited={article.favourited} /> : null}
              </div> : null}
          </div>
        </div>
      </article>
      <RelatedArticles article={article} />
    </main>
    : null);
};

export default Newsletter;