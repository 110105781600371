// pages/Onboarding/Flow.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import PageOne from './PageOne';
import PageTwo from './PageTwo';
import PageThree from './PageThree';
import PageFour from './PageFour';
import PageFive from './PageFive';

const OnboardingFlow = () => {
    return (
        <Routes>
            <Route path="/step-1" element={<PageOne />}    />
            <Route path="/step-2" element={<PageTwo />}    />
            <Route path="/step-3" element={<PageThree />}  />
            <Route path="/step-4" element={<PageFour />}   />
            <Route path="/step-5" element={<PageFive />}   />
        </Routes>
    );
};

export default OnboardingFlow;
