import React from 'react';
import { Link } from 'react-router-dom';
import {
    MindIcon, BodyIcon, SurroundingsIcon,
    RelationshipsIcon, MoneyIcon, CareerIcon
} from '../../components/Icons';

const PageOne = () => {
    return (
        <main>
            <section>
                <div className='container py-8 lg:py-12'>
                    <h1 className="mb-4">The 6 pillars of Wellbeing</h1>
                    <div className="grid gap-4 mb-5 grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <MindIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Mind</h3>
                        </div>

                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <BodyIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Body</h3>
                        </div>

                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <SurroundingsIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Surroundings</h3>
                        </div>

                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <RelationshipsIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Relationships</h3>
                        </div>

                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <MoneyIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Money</h3>
                        </div>

                        <div className="flex flex-col bg-white p-8 lg:py-16 gap-4 lg:gap-8 text-center">
                            <CareerIcon className='mx-auto max-w-full max-h-[90px]' />
                            <h3 className="h4 mt-2">Career</h3>
                        </div>
                    </div>
                    <div className='mb-6 prose'>
                        <p>
                            Wellbeing is fundamental to your health and overall happiness - enhancing your wellbeing isn’t always easy, but it is always worth it.
                        </p>
                        <p>
                            OptiMe is here to support you on your journey to improving your wellbeing. This can be achieved by focusing on the Six Pillars of Wellbeing
                        </p>
                    </div>
                    <Link to="../step-2" className="btn btn-purple block lg:inline-block lg:min-w-[250px]">NEXT</Link>
                </div>
            </section>
        </main>
    );
};

export default PageOne;
