import React from 'react';

const CareerIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 107 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.383789" y="15.9336" width="106.234" height="69.0519" fill="#4A0D6F"/>
        <rect x="37.5654" width="31.8701" height="15.9351" fill="#4A0D6F"/>
        <path d="M106.619 42.494L53.5016 53.1173L0.384766 42.494V15.9355H106.619V42.494Z" fill="#926EA9"/>
    </svg>
);

export default CareerIcon;
