import React from 'react';

const LogoSpinner = ({ className, style }) => (
    <svg className={className} style={style} width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.6703 54.1776C7.59062 55.3926 6.46797 57.7439 6.75566 60.1354C7.96255 70.1633 12.3304 79.8941 19.8908 87.7173C36.9191 105.337 64.169 107.111 83.249 92.7284C85.158 91.2895 86.0282 88.8464 85.471 86.5215C83.4041 77.8976 79.0765 69.6965 72.4673 62.8578C55.6876 45.4948 29.6301 42.5162 9.6703 54.1776Z" fill="currentColor"/>
        <path d="M54.5273 20.5257C57.011 18.1255 58.6518 15.1828 59.4463 12.0652C60.1717 9.21807 58.039 6.41184 55.1015 6.3695C46.4552 6.24447 37.7748 8.44214 30.0745 12.9789C27.5551 14.4634 27.0488 17.9423 29.0748 20.0514C29.0876 20.0649 29.1004 20.0783 29.1136 20.0917C36.0114 27.2293 47.3898 27.4236 54.5273 20.5257Z" fill="currentColor"/>
        <path d="M74.7694 55.9289C80.9118 62.2845 89.0385 65.5638 97.2283 65.7353C99.494 65.7828 101.456 64.131 101.81 61.8926C104.083 47.5146 99.8141 32.2805 88.9193 21.0072C85.7041 17.6803 82.1235 14.9199 78.3028 12.7257C76.3425 11.5999 73.8342 12.1025 72.4493 13.889C62.8074 26.3264 63.5133 44.2815 74.7694 55.9289Z" fill="currentColor"/>
    </svg>
);

export default LogoSpinner;
