import React from 'react';
import { Link } from 'react-router-dom';

import { RewardIcon, BodyIcon, WorkshopIcon, BookIcon } from '../../components/Icons';

const cardData = [
  {
    Icon: RewardIcon,
    title: "Daily Reward",
    details: "Log in everyday",
    points: "1pt"
  },
  {
    Icon: BodyIcon,
    title: "My Wellbeing",
    details: "Respond to your monthly wellbeing questions",
    points: "10pts"
  },
  {
    Icon: WorkshopIcon,
    title: "Workshops",
    details: "Watch your chosen wellbeing workshop of the month",
    points: "50pts"
  },
  {
    Icon: BookIcon,
    title: "Other Content",
    details: "Watch, read & review any other content to receive extra points",
    points: "1pt"
  }
];


const PageThree = () => {
    return (
        <main>
             <section>
                <div className='container py-8 lg:py-12'>
                    <h1 className="mb-4">The Points System</h1>
                    <div className="mb-6 grid gap-4 lg:grid-cols-2">
                        {cardData.map((card, index) => (
                            <div key={index} className="bg-white flex items-center p-8 gap-4 lg:gap-8 flex-col lg:flex-row text-center lg:text-left">
                                <card.Icon className='mx-auto max-w-full max-h-[90px]' />
                                <div className='flex-1'>
                                    <h3 className='mb-2'>{card.title}</h3>
                                    <p>{card.details}</p>
                                </div>
                                <div>
                                    <span className="whitespace-nowrap px-3 py-2 font-medium bg-[#FAF9F5] rounded-full">{card.points}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                    <Link to="../step-4" className="btn btn-purple block lg:inline-block lg:min-w-[250px]">NEXT</Link>
                </div>
            </section>
        </main>
    );
};

export default PageThree;