import React from 'react';

import { ChevronDoubleLeftIcon, ChevronLeftIcon, ChevronDoubleRightIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const getPages = (currentPage, totalPages) => {
    let pages = []

    if (currentPage - 2 > 0) {
        const number = currentPage - 2
        pages.push(number)
    }

    if (currentPage - 1 > 0) {
        const number = currentPage - 1
        pages.push(number)
    }

    pages.push(currentPage)

    if ((currentPage + 1) <= totalPages) {
        const number = currentPage + 1
        pages.push(number)
    }

    if ((currentPage + 2) <= totalPages) {
        const number = currentPage + 2
        pages.push(number)
    }

    return pages
}

const getStartIndex = (currentPage, data, pagination) => {
    if (currentPage === 1) {
        return 1
    }

    return 1 + (pagination.per_page * (currentPage - 1))
}

const getEndIndex = (currentPage, data, pagination) => {
    if (currentPage === 1) {
        return data.length
    }

    return data.length + (pagination.per_page * (currentPage - 1))
}

const Pagination = ({ currentPage, data, pagination, callback, className = '' }) => {
    return (
        <div className={`flex items-center justify-between mb-6 ${className}`}>
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm">
                        Showing <span className="font-bold">{getStartIndex(currentPage, data, pagination)}</span> to <span className="font-bold">{getEndIndex(currentPage, data, pagination)}</span>
                        {' '} of <span className="font-bold">{pagination.count}</span> results
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md" aria-label="Pagination">
                        <button
                            onClick={() => callback(1)}
                            className="transition-all relative inline-flex items-center rounded-l-md px-2 py-2 border hover:bg-purple hover:text-white"
                        >
                            <span className="sr-only">First</span>
                            <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                            onClick={() => callback(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="transition-all relative inline-flex items-center  px-2 py-2 border hover:bg-purple hover:text-white"
                        >
                            <span className="sr-only">Previous</span>
                            <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        {getPages(currentPage, pagination.num_pages).map((page) => (
                            <button key={page} onClick={() => callback(page)}
                                className={`transition-all relative z-10 inline-flex items-center px-4 py-2 border hover:bg-purple hover:text-white ${currentPage === page ? 'text-white bg-purple' : ''}`}>{page}</button>
                        ))}
                        <button
                            onClick={() => callback(currentPage + 1)}
                            disabled={currentPage === pagination.num_pages}
                            className="transition-all relative inline-flex items-center px-2 py-2 border hover:bg-purple hover:text-white"
                        >
                            <span className="sr-only">Next</span>
                            <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                            onClick={() => callback(pagination.num_pages)}
                            className="transition-all relative inline-flex items-center rounded-r-md px-2 border hover:bg-purple hover:text-white"
                        >
                            <span className="sr-only">Last</span>
                            <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
};

export default Pagination;
