import React from 'react';

const FormSelect = ({ id, name, label, options, value, inputRef = null, onChange = null, className = '' }) => {
    return (
        <div className={`${className}`}>
            {label ? <label htmlFor={id} className='block font-medium leading-tight mb-1'>{label}</label> : null }
            <select id={id} name={name} className='w-full rounded-full px-6 pl-3 pr-6 border bg-transparent'
                defaultValue={value}
                onChange={onChange}
                ref={inputRef}
            >
                {options.map(option => (
                    <option value={option.value} key={option.value}>{option.label}</option>
                ))}
            </select>
        </div>
    );
};

export default FormSelect;