import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

const BaseLayout = ({ children }) => {
    return (
        <div>
            <Header />

            <Outlet />

            <Footer links={false} />
        </div>
    );
};

export default BaseLayout;
