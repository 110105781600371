const occupationActivities = [
    {
        'value': 'mainly_desk',
        'label': 'Mainly desk based'
    },
    {
        'value': 'mainly_standing',
        'label': 'Mainly standing'
    },
    {
        'value': 'mainly_driving',
        'label': 'Mainly driving'
    },
    {
        'value': 'desk_driving',
        'label': 'Desk based and driving'
    },
    {
        'value': 'desk_standing',
        'label': 'Desk based and standing'
    },
    {
        'value': 'standing_driving',
        'label': 'Standing and driving'
    },
];

export default occupationActivities