import React from 'react';

const FormCheckbox = ({ id, name, label, value, inputRef = null, onChange = null, className = null }) => {
    return (
        <div className={`${className}`}>
            <label htmlFor={id} className='flex gap-2 items-center font-medium'>
                <span className='mr-2 w-[28px] h-[28px] flex-none rounded-full border-2 border-purple flex items-center justify-center'>
                    <input id={id} type="checkbox" name={name} className='w-[12px] h-[12px] rounded-full border-none text-white checked:bg-none checked:text-purple focus:ring-0'
                        defaultChecked={value}
                        onChange={onChange}
                        ref={inputRef} />
                </span>
                {label}
            </label>
        </div>
    );
};

export default FormCheckbox;