import React from 'react'

import Highcharts, { color } from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_more from "highcharts/highcharts-more";

// init the module
HC_more(Highcharts);

const RadarChart = ({ colour, title, data }) => {

    const labels = data.map(d => {
        return d.word
    })

    const values = data.map(d => {
        return d.usage
    })

    const options = {
        colors: [
            'rgba(' + colour + ',1)'
        ],
        chart: {
            polar: true,
            type: 'line',
            color:  'rgba(' + colour + ',1)'
        },
        title: {
            text: null
        },
        xAxis: {
            categories: labels,
            tickmarkPlacement: 'on',
            lineWidth: 0
        },
        yAxis: {
            gridLineInterpolation: 'polygon',
            lineWidth: 0,
            min: 0,
        },
        tooltip: {
            shared: true,
            pointFormat: '<span><b>{key}</b>{point.y}</span>'
        },
        series: [{
            name: title,
            data: values,
            pointPlacement: 'on',
            color: 'rgba(' + colour + ',0.5)'
        }],
        credits: {
            enabled: false
        },
        exporting: {
            showTable: true
        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default RadarChart;