// pages/CreateAccount/Flow.js

import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Landing from './Landing';
import Form from './Form';

const RegisterAccountFlow = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="form" element={<Form />} />
        </Routes>
    );
};

export default RegisterAccountFlow;
