import React from 'react';

const MindIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 89 96" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.268555 36.8323C0.268555 16.9044 16.4233 0.75 36.3506 0.75C57.4919 0.75 76.0349 14.8602 81.6694 35.2374L88.4941 56.5554H72.1328V96H45.1416C20.3589 96 0.268555 75.9091 0.268555 51.1261V36.8323Z" fill="#4A0D6F"/>
        <path d="M60.989 37.2541C60.989 25.3405 51.53 15.1768 39.1931 14.4301C25.675 13.6115 14.4053 23.9774 14.4053 36.8335V37.2541C14.4053 40.3852 17.0383 42.9231 20.2869 42.9231H55.1072C58.3557 42.9231 60.989 40.3852 60.989 37.2541Z" fill="#926EA9"/>
    </svg> 
);

export default MindIcon;
