import {
    MindIcon, BodyIcon, CareerIcon,
    SurroundingsIcon, RelationshipsIcon, MoneyIcon, MindIconResults,
    BodyIconResults, CareerIconResults, SurroundingsIconResults, RelationshipsIconResults, MoneyIconResults
} from '../components/Icons';

const pillarNameMap = {
    'MyMind': "mental",
    "MyBody": "physical",
    "MyCareer": "professional",
    "MySurroundings": "environmental",
    "MyRelationships": "social",
    "MyMoney": "financial"
}

const pillars = {
    "mental": {
        'title': 'Mind',
        'theme': 'purple',
        'colour': '#4A0D6F',
        'icon': (...additionalClassNames) => <MindIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <MindIconResults className={`${additionalClassNames.join(' ')}`} />
    },
    "physical": {
        'title': 'Body',
        'theme': 'blue',
        'colour': '#2C9AC9',
        'icon': (...additionalClassNames) => <BodyIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <BodyIconResults className={`${additionalClassNames.join(' ')}`} />
    },
    "professional": {
        'title': 'Career',
        'theme': 'purple-light',
        'colour': '#926EA9',
        'icon': (...additionalClassNames) => <CareerIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <CareerIconResults className={`${additionalClassNames.join(' ')}`} />
    },
    "environmental": {
        'title': 'Surroundings',
        'theme': 'green',
        'colour': '#77BB0E',
        'icon': (...additionalClassNames) => <SurroundingsIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <SurroundingsIconResults className={`${additionalClassNames.join(' ')}`} />
    },
    "social": {
        'title': 'Relationships',
        'theme': 'red',
        'colour': '#EC4A18',
        'icon': (...additionalClassNames) => <RelationshipsIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <RelationshipsIconResults className={`${additionalClassNames.join(' ')}`} />
    },
    "financial": {
        'title': 'Money',
        'theme': 'dark-blue',
        'colour': '#1E214F',
        'icon': (...additionalClassNames) => <MoneyIcon className={`${additionalClassNames.join(' ')}`} />,
        'icon_alt': (...additionalClassNames) => <MoneyIconResults className={`${additionalClassNames.join(' ')}`} />
    }
}

export const pillarMap = () => {
    return pillars
}

export const pillarMapper = (pillar) => {
    return pillar in pillars ? pillars[pillar] : null
}

export const pillarNameMapper = (pillar) => {
    return pillarMapper(pillarNameMap[pillar])
}