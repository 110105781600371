import React, { useState } from 'react';
import { Link } from 'react-router-dom'; 

import { OptimeLogoMono } from '../Icons';
import GoogleTranslate from '../../components/Elements/GoogleTranslate';

const Footer = ({ links = true, className = '' }) => {
    const currentYear = new Date().getFullYear();
    const [language, setLanguage] = useState('');
    const handleLanguageChange = (event) => { setLanguage(event.target.value); };

    return (
        <footer className={`border-t ${className}`}>
            <div className='container py-8 flex items-center text-black flex-col gap-8 text-center lg:text-left lg:flex-row lg:justify-between'>
                <div>
                    <OptimeLogoMono className="mx-auto mb-2 lg:mx-0" alt="Mono Logo" />
                    <p className="text-sm mb-3">&copy; {currentYear} Optime Limited</p>
                    {links ? <p className="text-xs">View our <Link to="/terms" className="font-bold">Terms of Use</Link>, <Link to="/privacy" className="font-bold">Privacy Policy</Link>, and our <Link to="/cookies" className="font-bold">Cookies Policy</Link>.</p> : null}
                </div>
                <GoogleTranslate />
            </div>
        </footer>
    );
}

export default Footer;
