import React from 'react';

const BookIcon = ({ className, style }) => (
    <svg className={className} style={style} width="50" height="74" viewBox="0 0 50 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40.7218 0L0 11.3406V73.7115L40.7218 62.3713V0Z" fill="#1D2151"/>
        <rect y="11.3398" width="49.9999" height="62.3713" fill="#2B9AC9"/>
    </svg>
);

export default BookIcon;
