import React from 'react';

const RelationshipsIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="41.414" height="39" viewBox="0 0 41.414 39">
        <g id="Group_173" data-name="Group 173" transform="translate(0)">
            <path id="Path_315" data-name="Path 315" d="M19.776,3.568a11.188,11.188,0,0,0-16.383,0,12.629,12.629,0,0,0,0,17.227l.931.979L20.707,39V4.546Z" transform="translate(0 0)" fill="#fff"/>
            <path id="Path_316" data-name="Path 316" d="M112.223,3.568a11.188,11.188,0,0,0-16.383,0l-.931.979V39l16.383-17.227.931-.979a12.629,12.629,0,0,0,0-17.227" transform="translate(-74.203 0)" fill="#4a0d6f"/>
            <rect id="Rectangle_143" data-name="Rectangle 143" width="41.414" height="39" transform="translate(0 0)" fill="none"/>
        </g>
    </svg>
);

export default RelationshipsIconResults;

