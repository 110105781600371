import React from 'react';
import { Link } from 'react-router-dom'; 

import { BodyIcon } from '../../components/Icons';

const PageTwo = () => {
    return (
        <main>
            <section>
                <div className='container py-8 lg:py-12'>
                    <div className='grid gap-8 lg:grid-cols-2'>
                        <div className='lg:order-2 self-center'>
                            <BodyIcon 
                                className="lg:mx-auto w-full max-w-[100px] lg:max-w-[200px]"
                            />
                        </div>
                        <div>
                            <h1 className="mb-4">MyWellbeing.</h1>
                            <div className='prose mb-6'>
                                <p>
                                    We’ve crafted a set of questions related to the six Pillars of Wellbeing. 
                                    Your responses are confidential, and by answering these questions about your wellbeing journey, you will assist us in creating personalised content tailored specifically for you each month.
                                </p>
                            </div>
                            <Link to="../step-3" className="btn btn-purple block lg:inline-block lg:min-w-[250px]">NEXT</Link>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PageTwo;
