import React, {useState, useEffect} from 'react';

import requestClient from '../../utils/requestClient';
import {StarIcon} from '@heroicons/react/24/solid'
import {StarIcon as OutlineStar} from '@heroicons/react/24/outline'

const RatingButton = ({ article}) => {
    const [rating, setRating] = useState(0);

    useEffect(() => {
        async function getRating() {
            const response = await requestClient().get(`/api/v2/rating/${article.content_type}/${article.id}/`)
            setRating(response.data.data.rating)
        }
        getRating();
     }, []);

    async function updateRating(rating) {
        const response = await requestClient().post(`/api/v2/rating/${article.content_type}/${article.id}/`, {
            'rating': rating
        })
        setRating(rating)
    }

    return (
        <div className='flex gap-2 items-center'>
            Rate:
            <ul className='flex gap-1 items-center text-purple'>
                {[1, 2, 3, 4, 5].map(value => (
                    <li key={value}><button onClick={() => updateRating(value)}>{rating >= value ? (<StarIcon className='h-5' />) : (<OutlineStar className='h-5' />)}</button></li>
                ))}
            </ul>
        </div>
    );
};

export default RatingButton;


