import React from 'react';

const UpArrowIcon = ({ className, style }) => (
<svg className={className} style={style} width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 0L20.4593 10.5H0.540708L10.5 0Z" fill="#77BB0E"/>
</svg>

);

export default UpArrowIcon;
