import React from 'react';
import { Link } from 'react-router-dom'; 

import { OptimeLogoMono } from '../Icons';

const Header = ({ className = ''}) => {
    return (
        <header className={`my-5 ${className}`}>
            <div className='container'>
                <Link to="/"> 
                    <OptimeLogoMono />
                </Link>
            </div>
        </header>
    );
};

export default Header;
