const tasks = [
    {
        key: "watched_workshop",
        title: "Monthly Workshop",
        description: "Watch your chosen monthly workshop",
        points: 50
    },
    {
        key: "other_goal",
        title: "Complete your workshop tasks",
        description: "Mark off your tasks as you complete them",
        points: 1
    },
    {
        key: "completed_three_logins_this_month",
        title: "3 days / month",
        description: "Log in 3 days in a month",
        points: 100
    }
]

export default tasks