import React from 'react';
import TooltipIcon from '../Icons/TooltipIcon';


const Tooltip = ({ text }) => {
    return (
        <div className="hidden group relative lg:block">
            <TooltipIcon />
            <div className='shadow w-[250px] absolute top-0 left-[20px] hidden bg-purple rounded text-white p-4 text-sm group-hover:block'>
                {text}
            </div>
        </div>
    );
};

export default Tooltip;
