import React, {useRef, useState} from 'react';
import { Link } from 'react-router-dom'; 
import requestClient from '../../utils/requestClient';

import FormInput from '../../components/Elements/Form/FormInput';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import ErrorMessage from '../../components/Elements/ErrorMessage';

const ResetPassword = () => {
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(null);

    const emailRef = useRef(null)

    async function handleSubmit(event) {
        setSuccess(false)
        setErrors(null)

        event.preventDefault()

        await requestClient().post('/api/v2/password-reset/', {
            email: emailRef.current.value
        }).then(response => {
            setSuccess(true)
        }).catch(error => {
            setErrors(error.response.data.error)
        })
    }

    return (
        <main>
            <section>
                 <div className='container py-8 lg:py-12 lg:max-w-[500px]'>
                    <h1 className="text-center  h2 mb-8">
                        Password Reset
                    </h1>
                    { success ? <SuccessMessage title="Password Reset Success"  text="We've emailed you instructions for setting your new password. 
                    If you don't receive an email, please can you make sure you've entered the email address you registered with and check your spam folder." className="mb-4" /> : null }
                    { errors ? <ErrorMessage title="Something is not right"  errors={errors} className="mb-4" /> : null}
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-5'>
                            <FormInput type="email" name="email" label="Email:" inputRef={emailRef} props={{'required': 'required'}}/>
                            <button className="btn btn-purple block">RESET</button>
                            <Link to="/login" className="btn btn-invert block">Back to Login</Link>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
};

export default ResetPassword;
