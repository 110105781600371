import React from 'react';
const Profile = () => {
    return (
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between border-b pb-8 mb-8'>
                        <h1>Cookie Policy</h1>
                    </div>
                    <div className="prose">
                        <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
                            with a good experience when you browse our website and also allows us to improve our site.</p>
                        <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                            computer if you agree. Cookies contain information that is transferred to your computer's hard
                            drive.</p>
                        <p>We use the following cookies:</p>
                        <ul>
                            <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation
                                of our website. They include, for example, cookies that enable you to log into secure areas of our
                                website. </li>
                            <li><strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number
                                of visitors and to see how visitors move around our website when they are using it. This helps us to
                                improve the way our website works, for example, by ensuring that users are finding what they are
                                looking for easily. </li>
                            <li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our
                                website. This enables us to personalise our content for you and remember your preferences (for
                                example, your choice of language or region). </li>
                            <li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you
                                have visited and the links you have followed. We will use this information to make our website and
                                the content displayed on it more relevant to your interests. We may also share this information with
                                third parties for this purpose. </li>
                        </ul>
                        <p>You can find more information about the individual cookies we use and the purposes for which we use them
                            in the table below:</p>
                        <div class="table-responsive">
                            <table class="table table-bordered">
                                <tbody class="blackBorder">
                                    <tr class="blackBorder">
                                        <td class="blackBorder"><p><strong>Cookie Title</strong></p>
                                            <p><strong>Cookie Name</strong></p></td>
                                        <td class="blackBorder"><p><strong>Purpose</strong></p></td>
                                    </tr>
                                    <tr class="blackBorder">
                                        <td class="blackBorder"><p>Google Analytics</p></td>
                                        <td class="blackBorder"><p>This cookie enables us to:</p>
                                            <p>(a) Estimate our audience size and usage pattern.</p>
                                            <p>(b) Store information about your preferences, and so allow us to customise our site and to
                                                provide you with the most optimal experience going forward.</p>
                                            <p>(c) Recognise you when you return to our site.</p>
                                            <p>(d) Allow you to use our site in a way that makes your browsing experience more convenient.
                                                If you register with us or complete our online forms, we will use cookies to remember your
                                                details during your current visit, and any future visits provided the cookie was not deleted
                                                in the interim.</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>Please note that third parties may also use cookies, over which we have no control. These named third
                            parties may include, for example, advertising networks and providers of external services like web
                            traffic analysis services. These third party cookies are likely to be analytical cookies or performance
                            cookies or targeting cookies.</p>
                        <p>To deactivate the use of third party advertising cookies, you may visit the consumer page to manage the
                            use of these types of cookies.</p>
                        <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of
                            all or some cookies. However, if you use your browser settings to block all cookies (including essential
                            cookies) you may not be able to access all or parts of our website.</p>
                        <p>Except for essential cookies, all cookies will expire after a reasonable period of time.</p>
                    </div>
                </div>
            </section>
        </main>);
};

export default Profile;
