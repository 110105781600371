import React from 'react';

const PurpleRelationshipsIcon = ({ className, style }) => (
    <svg className={className} style={style} width="333" height="297" viewBox="0 0 333 297" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M159.047 27.1721C122.82 -9.05476 64.0862 -9.05476 27.8593 27.1721C-8.3675 63.3989 -8.3675 122.133 27.8593 158.36L35.3123 165.813L166.5 297.001V34.625L159.047 27.1721Z" fill="#A972BE"/>
        <path d="M305.141 27.1721C268.914 -9.05476 210.18 -9.05476 173.953 27.1721L166.5 34.625V297.001L297.688 165.813L305.141 158.36C341.367 122.133 341.367 63.3989 305.141 27.1721Z" fill="#59007A"/>
    </svg>
);

export default PurpleRelationshipsIcon;
