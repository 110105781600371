import React from 'react';

const RewardIcon = ({ className, style }) => (
    <svg className={className} style={style} width="55" height="80" viewBox="0 0 55 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.44531 41.123V79.8753L27.4998 72.5413L47.5546 79.8753V41.123H7.44531Z" fill="#2B9AC9"/>
        <path d="M55 27.5C55 42.6877 42.6877 55 27.5 55C12.3123 55 0 42.6877 0 27.5C0 12.3123 12.3123 0 27.5 0C42.6877 0 55 12.3123 55 27.5Z" fill="#F4E305"/>
        <path d="M44 27.5C44 36.6128 36.6128 44 27.5 44C18.3872 44 11 36.6128 11 27.5C11 18.3872 18.3872 11 27.5 11C36.6128 11 44 18.3872 44 27.5Z" fill="#BDAD05"/>
    </svg>
);

export default RewardIcon;
