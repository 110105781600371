import React, {useState, useRef} from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom'; 

import FormInput from '../../components/Elements/Form/FormInput';
import FormSelect from '../../components/Elements/Form/FormSelect';
import FormCheckbox from '../../components/Elements/Form/FormCheckbox';
import ErrorMessage from '../../components/Elements/ErrorMessage';

import requestClient from '../../utils/requestClient';
import occupationActivities from '../../utils/occupationActivities';

const Form = () => {
    const { id, token } = useParams();

    const [errors, setErrors] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [user, setUser] = useState({
        email: null,
        password: null,
        first_name: null,
        last_name: null,
        occupational_activity: occupationActivities[0].value,
        email_opt_in: false,
        dairy_free: false,
        meat_free: false,
        gluten_free: false,
        vegan: false,
    });

    const updateUser = (key, value) => {
        const newUser = user;
        newUser[key] = value;
        setUser(newUser);
    }; 

    const steps = [
        {
            title: 'Your Account'
        },
        {
            title: 'About You'
        },
        {
            title: 'Your Dietary Requirements'
        }
    ]

    async function handleSubmit(event) {
        event.preventDefault()

        setErrors(null)

        await requestClient().post(`/api/v2/register/${id}/${token}/`, user)
        .then(response => {
            if(response.status === 201){
               // Can't use navigate here for some reason
                window.location.href = '/'
            }
        })
        .catch(error => {
            setErrors(error.response.data.error)
        })
    }

    const Step1 = ({className}) => {
        return (
            <form className={`block grid gap-5 ${className}`} onSubmit={() => setCurrentStep(currentStep + 1)}>
                <FormInput type="email" name="email" label="Email:" value={user.email} onChange={(event) => updateUser('email', event.currentTarget.value)} props={{'required': 'required'}} />
                <FormInput type="password" name="password" label="Password:" value={user.password} onChange={(event) => updateUser('password', event.currentTarget.value)} props={{'required': 'required'}} />
                <button className="btn btn-purple block">CONTINUE</button>
                <Link to="../" className="btn btn-invert block">Back</Link>
            </form>
        )
    }

    const Step2 = ({className}) => {
        return (
            <form className={`block grid gap-5 ${className}`} onSubmit={() => setCurrentStep(currentStep + 1)}>
                <FormInput type="text" name="first_name" label="First Name:" value={user.first_name} onChange={(event) => updateUser('first_name', event.currentTarget.value)} props={{'required': 'required'}} />
                <FormInput type="text" name="last_name" label="Last Name:" value={user.last_name} onChange={(event) => updateUser('last_name', event.currentTarget.value)} props={{'required': 'required'}} />
                <FormSelect name="occupational_activity" label="Occupational Activity:" options={occupationActivities} value={user.occupational_activity} onChange={(event) => updateUser('occupational_activity', event.currentTarget.value)} props={{'required': 'required'}} />
                <FormCheckbox name="email_opt_in" label="Opt in to email notifications" value={user.email_opt_in} onChange={(event) => updateUser('email_opt_in', event.currentTarget.checked)} />
                <button className="btn btn-purple block">CONTINUE</button>
                <button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-invert block">Back</button>
            </form>
        )
    }

    const Step3 = ({className}) => {
        return (
            <form className={`block grid gap-5 ${className}`} onSubmit={handleSubmit}>
                <FormCheckbox name="dairy_free" label="Dairy Free" value={user.dairy_free} onChange={(event) => updateUser('dairy_free', event.currentTarget.checked)} />
                <FormCheckbox name="meat_free" label="Meat Free" value={user.meat_free} onChange={(event) => updateUser('meat_free', event.currentTarget.checked)} />
                <FormCheckbox name="gluten_free" label="Gluten Free" value={user.gluten_free} onChange={(event) => updateUser('gluten_free', event.currentTarget.checked)} />
                <FormCheckbox name="vegan" label="Vegan" value={user.vegan} onChange={(event) => updateUser('vegan', event.currentTarget.checked)} />
                <button className="btn btn-purple block">CREATE</button>
                <button onClick={() => setCurrentStep(currentStep - 1)} className="btn btn-invert block">Back</button>
            </form>
        )
    }

    return (
        <main>
            <section>
                 <div className='container py-8 lg:py-12 lg:max-w-[500px]'>
                    <h1 className="text-center h2 mb-8">
                        {steps[currentStep].title}
                    </h1>
                    { errors ? <ErrorMessage title="Profile not created"  errors={errors} className="mb-4" /> : null}            
                    <Step1 className={(currentStep === 0 ? 'block' : 'hidden')} />
                    <Step2 className={(currentStep === 1 ? 'block' : 'hidden')}/>
                    <Step3 className={(currentStep === 2 ? 'block' : 'hidden')}/>
                </div>
            </section>
        </main>
    );
};

export default Form;
