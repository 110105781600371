import React from 'react';

const BodyIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 194 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M129.433 31.9168C129.433 14.2897 115.143 0 97.515 0C79.8867 0 65.5957 14.2897 65.5957 31.9168C65.5957 49.5463 79.8867 63.8361 97.515 63.8361C115.143 63.8361 129.433 49.5463 129.433 31.9168Z" fill="#2B9AC9"/>
        <path d="M129.433 165.061V159.591V104.364C148.495 115.406 161.351 135.977 161.351 159.591H193.269C193.269 106.706 150.398 63.8359 97.5148 63.8359C45.1073 63.8359 2.55884 105.944 1.79553 158.169C1.78931 158.645 1.7594 159.115 1.7594 159.591H1.78308H33.6774C33.6774 159.111 33.7396 158.647 33.7496 158.169C34.2573 135.156 46.9209 115.182 65.5955 104.364V158.169V159.591V164.773C62.2157 196.921 34.493 222.006 0.730469 222.006V253.925C41.8887 253.925 76.9719 227.953 90.5109 191.509H97.5148C132.771 191.509 161.351 220.09 161.351 255.347H193.269C193.269 213.657 166.62 178.209 129.433 165.061Z" fill="#59007A"/>
    </svg>
);

export default BodyIcon;
