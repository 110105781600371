import React from 'react';

const WorkshopIcon = ({ className, style }) => (
    <svg className={className} style={style} width="64" height="55" viewBox="0 0 64 55" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.2402 55.0098H53.3437L33.2918 40.1055L13.2402 55.0098Z" fill="#4A0D6F"/>
        <rect x="0.910156" y="-0.0078125" width="63.0898" height="45.6093" fill="#926EA9"/>
        <rect x="0.910156" y="-0.00585938" width="63.0898" height="8.48342" fill="#4A0D6F"/>
    </svg>
);

export default WorkshopIcon;
