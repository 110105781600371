import React from 'react';
import { Link } from 'react-router-dom';

import { MindIcon, BodyIcon, SurroundingsIcon,
    RelationshipsIcon, MoneyIcon, CareerIcon } from '../../components/Icons';

const Landing = () => {
    return (
        <main>
            <section>
                <div className='container py-8 lg:py-12'>
                    <h1 className="mb-4">Reach Your Optimal Self</h1>
                    <div className='prose mb-6'>
                        <p className="lrg-p fg-tertiary mb-1">
                            At OptiMe, we believe in empowering every individual to achieve their best possible state of wellbeing. 
                            We understand that a healthy and happy workforce is the cornerstone of a successful business. 
                            That’s why we’ve created a space where personal growth and workplace wellbeing go hand in hand.
                        </p>
                    </div>
                    <Link to="../form" className="btn btn-purple block lg:inline-block lg:min-w-[250px]">GET STARTED</Link>
                    <div className="grid items-end gap-4 mt-4 grid-cols-3 lg:grid-cols-6">
                        <div>
                            <MindIcon className='mx-auto max-w-full max-h-[80px] lg:max-h-[140px]' />
                        </div>
                        <div>
                            <BodyIcon className='mx-auto max-w-full max-h-[80px] lg:max-h-[140px]' />
                        </div>
                        <div>
                            <SurroundingsIcon className='mx-auto max-w-full max-h-[120px] lg:max-h-[200px]' />
                        </div>
                        <div>
                            <RelationshipsIcon className='mx-auto max-w-full max-h-[80px] lg:max-h-[140px]' />
                        </div>
                        <div>
                            <MoneyIcon className='mx-auto max-w-full max-h-[80px] lg:max-h-[140px]' />
                        </div>
                        <div>
                            <CareerIcon className='mx-auto max-w-full max-h-[80px] lg:max-h-[140px]' />
                        </div>
                    </div>
                </div>
            </section>
            <section> 
                <div className='container py-8 lg:py-12'>
                    <h2>
                        It's more than wellbeing <br />— it’s your wellbeing at its best.
                    </h2>
                    <div className="grid gap-8 lg:grid-cols-2 mt-8 lg:mt-12">
                        <div>
                            <h3 className="text-green mb-4">
                                Access Wellbeing <br /> 
                                Anywhere, Anytime
                            </h3>
                           <div className='prose'>
                                <p>
                                    The journey to optimal wellbeing shouldn’t be limited by location or time. 
                                    With OptiMe, you carry a comprehensive wellbeing toolkit wherever you go. 
                                    Our platform is designed to fit seamlessly into your life, giving you the support you need, when you need it.
                                </p>
                           </div>
                        </div>
                        <div>
                            <h3 className="text-blue mb-4">
                                Track Your Progress <br />
                                Celebrate Your Success
                            </h3>
                            <div className='prose'>
                                <p>
                                    Your path to better health is unique, and so are the ways you reach your milestones. 
                                    OptiMe’s intuitive tracking features allow you to monitor your progress closely and celebrate every victory along the way. 
                                    Understand your growth with insightful metrics and personalised reports.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>  
    );
};

export default Landing;
