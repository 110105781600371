import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import Questionnaire from './Questionnaire';
import Results from './Results';

const QuestionnaireFlow = () => {
    return (
        <Routes>
            <Route path="/:id/" element={<Questionnaire />} />
            <Route path="/results" element={<Results />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default QuestionnaireFlow;
