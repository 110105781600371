import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import ArticleCard from '../../components/Elements/Cards/ArticleCard';

const ResourceList = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get(`/api/v2/employer/resources/`)

            setData(response.data.data)
        }
        getData();
    }, []);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='border-b pb-8 mb-8'>
                        <h1>Resources</h1>
                    </div>
                    <div className='grid gap-8 lg:grid-cols-3'>
                        {data.map((article, index) => (
                            <ArticleCard key={index} item={article} />
                        ))}
                    </div>
                </div>
            </section>
        </main>
        : null);
};

export default ResourceList;
