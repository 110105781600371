import React from 'react';

import {pillarMapper, pillarNameMapper} from '../../../utils/pillarMapper';

const Histogram = ({ histogramData, className = null }) => {
    return (
        <div className={`grid gap-1 ${className}`}>
            {histogramData.map((data, index) => (
                <div title={data.name} className="bg-grey-light" key={index}>
                    <div className={`transition-all duration-300 p-2 flex items-center justify-between text-white h-full min-w-[120px] bg-${pillarNameMapper(data.name).theme}`}  style={{'width': data.value * 10 + '%' }}>
                        {pillarNameMapper(data.name).icon_alt("h-[40px] mr-1")}
                        <span className='text-[28px]'>{data.value.toFixed(1)}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Histogram;
