import Cookies from 'js-cookie'
import axios from 'axios'

import React, { useEffect, useState, createContext } from 'react';

import requestClient from './utils/requestClient';
import {loadCsrfToken} from './utils/api';
import EmployeeRoutes from './routers/EmployeeRoutes';
import EmployerRoutes from './routers/EmployerRoutes';
import PublicRoutes from './routers/PublicRoutes';


export const CurrentUser = createContext({})

function App() {
    const [user, setUser] = useState(null);
    const [loaded, setLoaded] = useState(null);

    useEffect(() => {
        async function getUser() {
            loadCsrfToken()

            await requestClient().get('/api/v2/user').then(response => {
                setUser(response.data.data);
            }).catch(error => {
            
            })

            setLoaded(true)
        }
        getUser();
    }, []);

    return (!loaded ? null :
        (user ? <CurrentUser.Provider value={user}>
            {user.user.user_type === 'employer' ? <EmployerRoutes /> : <EmployeeRoutes />}
        </CurrentUser.Provider> : <PublicRoutes />)
    );
}

export default App;

