import React, {useState} from 'react';

const FormFile = ({ id, name,label, text='Choose a file...', inputRef = null, className = '', props = {} }) => {
    const [inputPlaceholder, setInputPlaceholder] = useState(text);
    return (
        <div className={`${className}`}>
            <p className='block font-medium leading-tight mb-1'>{label}</p>
            <label className='block w-full rounded-full border flex justify-between'>
                <span className='px-6 py-3'>
                    {inputPlaceholder}
                </span>
                <input id={id} type="file" name={name} className='absolute h-0 w-0' onChange={(event) => {(setInputPlaceholder(event.target.files[0].name))}} {...props} />
                <span className='px-6 py-3 bg-purple text-white rounded-r-full'>Select a file</span>
            </label>
        </div>
    );
}

export default FormFile;