import React from 'react';
import { Link } from 'react-router-dom';
import { MultiRewardIcon } from '../../components/Icons';

const PageFour = () => {
    return (
        <main>
            <section>
                <div className='container py-8 lg:py-12'>
                    <div className='grid gap-8 lg:grid-cols-2'>
                        <div className='lg:order-2 self-center'>
                            <MultiRewardIcon 
                                className="lg:mx-auto w-full max-w-[100px] lg:max-w-[250px]"
                            />
                        </div>
                        <div>
                            <h1 className="mb-4">Monthly Rewards</h1>
                            <div className='prose mb-6'>
                                <p>
                                    Complete three activities each month to receive access to a bonus reward workshop. 
                                    You’ll also be rewarded with points that can be traded for competition entries to win a range of fantastic prizes!    
                                </p>
                            </div>
                            <Link to="../step-5" className="btn btn-purple block lg:inline-block lg:min-w-[250px]">NEXT</Link>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PageFour;
