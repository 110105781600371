import React from 'react';

const SurroundingsIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="34" height="40" viewBox="0 0 34 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9999 23C17.9999 27.9705 13.9705 31.9999 8.99996 31.9999C4.02938 31.9999 0 27.9705 0 23C0 18.0294 4.02938 14 8.99996 14C13.9705 14 17.9999 18.0294 17.9999 23Z" fill="#4A0D6F"/>
        <rect x="8" y="28" width="1.99999" height="11.9999" fill="white"/>
        <rect x="21" y="28" width="3.99998" height="11.9999" fill="#4A0D6F"/>
        <path d="M12 31.9998L22.9999 0L33.9999 31.9998H12Z" fill="white"/>
    </svg>
);

export default SurroundingsIconResults;

