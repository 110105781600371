import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import Image from '../../components/Elements/Image';
import Video from '../../components/Elements/Video';

const Article = () => {
  const { id } = useParams();

  const [article, setArticle] = useState([]);

  useEffect(() => {
    async function getArticle() {
      const response = await requestClient().get(`/api/v2/employer/resources/${id}/`)
      setArticle(response.data.data);
    }
    getArticle();
  }, []);

  return (article ?
    <main>
      <article className='bg-white'>
        <div className='container py-8 lg:py-12'>
          <Link to={`/resources`} className="flex items-center mb-8 lg:hidden">← RESOURCES</Link>

          <div className='max-w-[768px] mx-auto'>
            <h1 className='mb-8'>{article.title}</h1>
            {article.video ? (
              <Video src={article.video} className="aspect-video w-full rounded-xl lg:rounded-2xl" />
            ) : null}
            {article.image ?
              (<Image src={article.image} className="aspect-video w-full rounded-xl lg:rounded-2xl" />)
              : null
            }
            {article.pdf_document ? (<a href={article.pdf_document} target='_blank' className="btn btn-purple mt-4 block lg:inline-block">Download Content</a>) : null}

            <section>
              {'additional_information' in article ? (
                <div className="mt-8 prose whitespace-pre-line">
                  <p>{article.additional_information}</p>
                </div>
              ) : null}
            </section>
          </div>
        </div>
      </article>
    </main>
    : null);
};

export default Article;
