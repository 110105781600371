import React from 'react';

const CareerIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="35.663" height="28.531" viewBox="0 0 35.663 28.531">
        <g id="Group_175" data-name="Group 175" transform="translate(0 0)">
            <rect id="Rectangle_145" data-name="Rectangle 145" width="10.489" height="5.245" transform="translate(12.587 0)" fill="#4a0d6f"/>
            <path id="Path_334" data-name="Path 334" d="M17.832,83.566,0,80V94.265H35.663V80Z" transform="translate(0 -65.734)" fill="#4a0d6f"/>
            <path id="Path_335" data-name="Path 335" d="M0,30v8.916l17.832,3.566,17.832-3.566V30Z" transform="translate(0 -24.651)" fill="#fff"/>
        </g>
    </svg>
);

export default CareerIconResults;

