import React from 'react';

const FormInput = ({ id, name, type, label, inputRef = null, onChange = null, value = '', className = '', props = {} }) => {
    return (
        <div className={`${className}`}>
            <label htmlFor={id} className='block font-medium leading-tight mb-1'>{label}</label>
            <input id={id} type={type} name={name} className='w-full rounded-full px-6 py-3 border'
                defaultValue={value}
                onChange={onChange}
                ref={inputRef} {...props} />
        </div>
    );
}

export default FormInput;