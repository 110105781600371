import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import NavBar from '../NavBar';
import Footer from '../Footer';

const NavLayout = ({ links, banner = false }) => {

    const location = useLocation();
    const isDashboard = [
        '/overview'
    ].includes(location.pathname);

    return (
        <div>            
            <NavBar links={links} banner={banner} />

            <Outlet />
            
            <Footer />
        </div>
    );
}

export default NavLayout;
