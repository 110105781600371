import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { CheckIcon } from '@heroicons/react/20/solid'


import requestClient from '../../utils/requestClient';
import tasks from '../../utils/tasks';
import { CurrentUser } from '../../App';
import Video from '../../components/Elements/Video';
import Image from '../../components/Elements/Image';

const Rewards = () => {
    const user = useContext(CurrentUser)
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get('/api/v2/awards/')
            setData(response.data.data);
        }
        getData();
    }, []);

    async function redeemReward(rewardId) {
        const response = await requestClient().post(`/api/v2/awards/`, { 'redeem': rewardId })
        setData(response.data.data);
    }

    async function enterCompetition(competitionId) {
        const response = await requestClient().post(`/api/v2/awards/`, { 'competition': competitionId })
        setData(response.data.data);
    }

    const RewardButton = ({ reward }) => {
        // If already redeemed
        if (data.current_reward_redeemed) {
            return (<span className="btn bg-grey text-[#807C81] block w-full">Redeemed</span>)
        }

        // If ready to be redeemd
        if (user.user.month_completed) {
            return (<button onClick={() => redeemReward(reward.id)} className="btn btn-purple block w-full">Redeem</button>)
        }

        return (<span className="btn btn-purple block w-full opacity-20">Complete your monthly goals</span>)
    }

    const CompetitionButton = ({ competition }) => {
        // If already redeemed
        if (data.entered_competition_pks.includes(competition.id)) {
            return (<span className="btn bg-grey text-[#807C81] block w-full">Entered</span>)
        }

        // If ready to be redeemd
        if (user.user.points >= competition.value) {
            return (<button onClick={() => enterCompetition(competition.id)} className="btn btn-purple block w-full">Enter</button>)
        }

        return (<span className="btn btn-purple block w-full opacity-20">{competition.value} Points</span>)
    }

    return (data && user ?
        <main>
            <section className='bg-white'>
                <div className='container py-8 lg:py-12'>
                    <Link to="/dashboard" className="flex items-center mb-8 lg:hidden">← DASHBOARD</Link>
                    <div className='text-center max-w-[768px] mx-auto mb-12'>
                        <h1 className='mb-4'>
                            Monthly Rewards
                        </h1>
                        <p>Complete three activities each month to receive access to a bonus reward workshop.
                            You'll also be rewarded with points that can be traded for competition entries to win a range of fantastic prizes!</p>
                    </div>

                    {data.current_rewards.map((reward, index) => (
                        <div key={reward.id} className='grid border border-purple rounded-2xl lg:grid-cols-2'>
                            <div className='bg-purple text-white rounded-2xl p-8'>
                                <div>
                                    { data.current_reward_redeemed && reward.video ?
                                        <Video src={reward.video} className="aspect-video w-full rounded-xl" /> :
                                        <Image src={reward.graphic} className="aspect-video w-full rounded-xl" />
                                    }
                                    <h3 className='text-white mt-3 mb-3'>{reward.title}</h3>
                                    <p>{reward.description}</p>
                                </div>
                            </div>

                            <div className='p-8 text-center'>
                                <span className="text-sm block">TO DO</span>
                                <h2>3 Activities</h2>
                                <ul className='mt-6 pt-6 border-t text-left'>
                                    {tasks.map((task, index) => (
                                        <li key={index} className="flex gap-4 justify-between mb-6">
                                            <div className="flex gap-4">
                                                <span className={`relative flex-none mr-2 w-[28px] h-[28px] rounded-full border-2 border-purple flex items-center justify-center ${ user.user[task.key] ? 'bg-purple': ''}`}>
                                                    { user.user[task.key] ? <CheckIcon className='absolute top-0 bottom-0 right-0 left-0 w-[20px] h-[20px] m-auto text-white' /> : null }
                                                </span>
                                                <div>
                                                    <h4 className='mb-1'>
                                                        {task.title}
                                                    </h4>
                                                    <p className="text-sm">
                                                        {task.description}
                                                    </p>
                                                </div>
                                            </div>
                                            <div>
                                                <span className="whitespace-nowrap px-3 py-2 text-sm bg-[#FAF9F5] rounded-full font-medium">{task.points} pts</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <RewardButton reward={reward} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <section>
                <div className='container py-8 lg:py-12'>
                    <h2 className="mb-8">
                        Competitions
                    </h2>

                    <div className="mb-8 flex gap-8 justify-between flex-col lg:flex-row lg:border-t lg:pt-8">
                        <div className="prose">
                            <p>
                                Would you like to win one of our competition prizes? Trade your points and enter today!<br />
                                Complete three activities each month to receive access to a bonus reward workshop. You’ll also be rewarded with points that can be traded for competition entries to win a range of fantastic prizes!
                            </p>
                        </div>
                        <div className="col-span-4">
                            <span className="text-center font-bold bg-[#9EF914] px-6 py-3 rounded-full text-xl block lg:inline-block">
                                You've earned {user.user.points} pts
                            </span>
                        </div>
                    </div>

                    <div className='overflow-hidden'>
                        <div className='scroll-bar grid grid-flow-col auto-cols-full lg:auto-cols-1/3 gap-8 pb-6'>
                            {data.current_competitions.map(competition => (
                                <div key={competition.id} className='lg:flex lg:flex-col'>
                                    <div className='relative'>
                                        <Image
                                            src={competition.graphic}
                                            alt={competition.title}
                                            className="w-full rounded-xl"
                                        />
                                        <span className="absolute top-2 right-2 block whitespace-nowrap px-3 py-2 text-sm bg-[#FAF9F5] rounded-full font-medium">{competition.value} pts</span>
                                    </div>
                                    <div className='mt-4 mb-6 lg:flex lg:grow lg:flex-col'>
                                        <h3 className="mb-2">{competition.title}</h3>
                                        <p>{competition.description}</p>
                                    </div>
                                    <CompetitionButton competition={competition} />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </main>
        : null);
};

export default Rewards;
