import React from 'react';

const MagnifierIcon = ({ className, style }) => (
    <svg className={className} style={style} width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.21045 8.89417C5.21045 6.85943 6.85992 5.20996 8.89466 5.20996" stroke="#030F27" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="8.89474" cy="8.89474" r="7.89474" stroke="#030F27" strokeLinecap="round" strokeLinejoin="round"/>
        <line x1="15.3912" y1="14.6846" x2="18.8946" y2="18.188" stroke="#030F27" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export default MagnifierIcon;
