import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import { resourceMapper } from '../../utils/resourceMapper';
import ArticleCard from '../../components/Elements/Cards/ArticleCard';
import Pagination from '../../components/Elements/Pagination';

const ResourceList = () => {
    const { type } = useParams();

    const [data, setData] = useState(null);
    const [currentPage, setCurrentPage] = useState(1)

    const getResources = async () => {
        const response = await requestClient().get(`/api/v2/resources/?type=${type}&page=${currentPage}`)
        setData(response.data)
    }

    useEffect(() => {
        async function getData() {
            await getResources()
        }
        getData();
    }, []);

    useEffect(() => {
        async function paginate() {
            await getResources()
        }
        paginate()
    }, [currentPage]);

    return (data ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='border-b pb-8 mb-8'>
                        <h1>{resourceMapper(type).title}</h1>
                    </div>
                    <div className='grid gap-8 lg:grid-cols-3 mb-8'>
                        {data.data.map((article, index) => (
                            <ArticleCard key={index} item={article} />
                        ))}
                    </div>
                    <Pagination currentPage={currentPage} data={data.data} pagination={data.meta.paginator} callback={setCurrentPage} />
                </div>
            </section>
        </main>
        : null);
};

export default ResourceList;
