import React, {useContext} from 'react';

import { CurrentUser } from '../../App';
import {getMonthName} from '../../utils/dateUtils';

const ScoreBar = ({ className = "" }) => {
    const user = useContext(CurrentUser)

    const now = new Date()

    const greeting = () => {
        const date = new Date(user.current_time)

        if (date.getHours() < 12){
            return  "Good morning"
        }
        else if(date.getHours() < 18){
            return "Good afternoon"
        }

        return  "Good evening"
    }

    return ( user ?
        <div className={`${className} `}>
            <div className='text-purple container pt-4 pb-8 flex items-center flex-col lg:flex-row gap-3'>
                <div className='flex-1 text-center lg:text-left'>
                    <span className='h1 block'>
                        {greeting()}, {user.user.first_name}
                    </span>
                    <p className='hidden mt-1 lg:block'>You've earned {user.user.points} points!</p>
                </div>
                <div className='flex-1 flex items-center flex-col lg:flex-row gap-3 lg:justify-end'>
                    <div className='flex items-center'>
                        <span className="text-[35px] font-bold mr-1">{user.user.points}</span>
                        <span className="text-sm">PTS</span>
                    </div>
                    <div className="flex items-center bg-white rounded-full px-4 py-4 gap-8">
                        {/* <div className="flex items-center"> 
                            <span className="h-[30px] w-[30px] block rounded-full text-center bg-purple-light-alt text-[20px] mr-2">{user.stats.workshops}</span>
                            <span className="text-xs leading-tight max-w-[60px]">Workshops Completed</span>
                        </div> */}
                        <div className="flex items-center"> 
                            <span className="h-[30px] w-[30px] block rounded-full text-center bg-purple-light-alt text-[20px] mr-2">{user.stats.competitions}</span>
                            <span className="text-xs leading-tight max-w-[60px]">Competitions Entered</span>
                        </div>
                        <div className="flex items-center"> 
                            <span className="h-[30px] w-[30px] block rounded-full text-center bg-purple-light-alt text-[20px] mr-2">{user.stats.logins}</span>
                            <span className="text-xs leading-tight max-w-[60px]">{getMonthName(now.getMonth())} Logins</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    : null);
};

export default ScoreBar;
