import React, { useState }  from 'react';

import {XCircleIcon, XMarkIcon} from '@heroicons/react/24/solid'

const ArrayErrors = ({errors}) => {
    return (
        errors.map((error, index) => (
            <li key={index}>{(Array.isArray(error) ? error.join(',') : error)}</li>
        ))
    )
}

const ObjectErrors = ({errors}) => {
    return (
        Object.keys(errors).map((key) => (
            <li key={key}>{(Array.isArray(errors[key]) ? errors[key].join(',') : errors[key])}</li>
        ))
    )
}

const ErrorsMessage = ({ title, errors, className = null}) => {
    const [isDismissed, setIsDismissed] = useState(false)

    return (
        !isDismissed ? <div className={`cursor-pointer rounded-md border bg-white border-red p-3 ${className}`} onClick={() => setIsDismissed(true)}>
            <div className="relative flex">
                <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-xl font-medium text-red leading-none mb-1">{title}</h3>
                    <div className="mt-1 text-base">
                        <ul role="list" className="list-disc space-y-1 pl-4">
                            {typeof errors === "array" ? (<ArrayErrors errors={errors} />) : null}
                            {typeof errors === "object" ? (<ObjectErrors errors={errors} />) : null}
                            {typeof errors === "string" ? (<li>{errors}</li>) : null}
                         </ul>
                    </div>
                </div>
                <XMarkIcon className='absolute right-0 top-0 w-5 h-5' />
            </div>
        </div> : null
    )
};

export default ErrorsMessage;
