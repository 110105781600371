import React, {useRef, useState} from 'react';
import { Link, useSearchParams } from 'react-router-dom'; 
import requestClient from '../../utils/requestClient';

import FormInput from '../../components/Elements/Form/FormInput';
import ErrorMessage from '../../components/Elements/ErrorMessage';


const Login = () => {
    const [searchParams] = useSearchParams()
    const [errors, setErrors] = useState(null);

    const emailRef = useRef(null)
    const passwordRef = useRef(null)

    async function handleSubmit(event) {
        event.preventDefault()

        await requestClient().post('/api/v2/login/', {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        }).then(response => {
             // Can't use navigate here for some reason
            if(searchParams && searchParams.get('next')){
                window.location.href = searchParams.get('next')
            }
            else{
                window.location.href = '/'
            }
        }).catch(error => {
            setErrors(error.response.data.error)
        })
    }

    return (
        <main>
            <section>
                 <div className='container py-8 lg:py-12 lg:max-w-[500px]'>
                    <h1 className="text-center  h2 mb-8">
                        Login
                    </h1>
                    { errors ? <ErrorMessage title="Something is not right"  errors={errors} className="mb-4" /> : null}
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-5'>
                            <FormInput type="email" name="email" label="Email:" inputRef={emailRef} props={{'required': 'required'}} />
                            <FormInput type="password" name="password" label="Password:" inputRef={passwordRef} props={{'required': 'required'}} />
                            <button className="btn btn-purple block">LOGIN</button>
                            <Link to="/reset-password" className="btn btn-invert block">Reset Password</Link>
                        </div>
                    </form>
                </div>
            </section>
        </main>
    );
};

export default Login;
