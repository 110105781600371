import React from 'react'

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { pillarMapper } from '../../../utils/pillarMapper';
import { getMonthName } from '../../../utils/dateUtils';

const WellbeingChart = ({ data, months }) => {

    const change = {
        5: 'Very Low',
        10: 'Low',
        15: 'Ok',
        20: 'Good',
        25: 'Very Good'
    };

    const categories = months.map((month) => {
        const d = new Date(month)
        return getMonthName(d.getMonth()) + ' ' + d.getFullYear()
    })

    const options = {
        colors: [
            pillarMapper('mental').colour,
            pillarMapper('physical').colour,
            pillarMapper('professional').colour,
            pillarMapper('environmental').colour,
            pillarMapper('social').colour,
            pillarMapper('financial').colour,
        ],
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subTitle: {
            text: ''
        },
        xAxis: {
            categories: categories,
            crosshair: true
        },
        yAxis: {
            min: 0,
            title: {
                text: ''
            },
            max: 25,
            labels: {
                formatter: function () {
                    var value = change[this.value];
                    return value !== 'undefined' ? value : this.value;
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:10px">{point.key}</span><table class="d-table">',
            pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
            footerFormat: '</table>',
            shared: true,
            useHTML: true
        },
        plotOptions: {
            column: {
                borderRadius: '50%',
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: data,
        credits: {
            enabled: false
        },
        exporting: {
            showTable: true
        }
    }

    return (
        <HighchartsReact highcharts={Highcharts} options={options} />
    );
};

export default WellbeingChart;