import React from 'react';

import {
    Routes, Route, Navigate
} from 'react-router-dom';

import NavLayout from '../components/Layout/NavLayout';
import Overview from '../pages/Employer/Overview';
import Employees from '../pages/Employer/Employees';
import Resources from '../pages/Employer/Resources';
import Resource from '../pages/Employer/Resource';
import Settings from '../pages/Employer/Settings';
import Profile from '../pages/Profile';
import PrivacyPolicy from '../pages/General/PrivacyPolicy';
import CookiePolicy from '../pages/General/CookiePolicy';
import Terms from '../pages/General/Terms';

const EmployerRoutes = () => {
    const links = [
        { path: "/overview", text: "Overview" },
        { path: "/settings", text: "Settings" },
        { path: "/employees", text: "Employees" },
        { path: "/resources", text: "Resources" }
    ];

    return (
        <Routes>
            <Route path="/" element={<NavLayout links={links} banner={true} />}>
                <Route path="/" element={<Navigate to="/overview" replace />} />
                <Route path="overview" element={<Overview />} />
            </Route>
            <Route path="/" element={<NavLayout links={links} />}>
                <Route path="employees" element={<Employees />} />
                <Route path="resources" element={<Resources />} />
                <Route path="resources/employer/:id" element={<Resource />} />
                <Route path="settings" element={<Settings />} />
                <Route path="profile" element={<Profile />} />
                <Route path="privacy" element={<PrivacyPolicy />} />
                <Route path="cookies" element={<CookiePolicy />} />
                <Route path="terms" element={<Terms />} />
                <Route path="*" element={<Navigate to="/" />} />
            </Route>
        </Routes>
    );
};

export default EmployerRoutes;