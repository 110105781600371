import React from 'react';

const MultiRewardIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 201 195" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M133.901 114.207V168.478L161.987 158.207L190.073 168.478V114.207H133.901Z" fill="#2B9AC9"/>
        <path d="M200.5 95.1278C200.5 116.397 183.257 133.64 161.987 133.64C140.717 133.64 123.475 116.397 123.475 95.1278C123.475 73.8581 140.717 56.6152 161.987 56.6152C183.257 56.6152 200.5 73.8581 200.5 95.1278Z" fill="#F4E305"/>
        <path d="M185.095 95.127C185.095 107.889 174.749 118.235 161.987 118.235C149.225 118.235 138.88 107.889 138.88 95.127C138.88 82.365 149.225 72.0195 161.987 72.0195C174.749 72.0195 185.095 82.365 185.095 95.127Z" fill="#BDAD05"/>
        <path d="M10.9268 114.207V168.478L39.0121 158.207L67.098 168.478V114.207H10.9268Z" fill="#2B9AC9"/>
        <path d="M77.525 95.1278C77.525 116.397 60.2822 133.64 39.0125 133.64C17.7428 133.64 0.5 116.397 0.5 95.1278C0.5 73.8581 17.7428 56.6152 39.0125 56.6152C60.2822 56.6152 77.525 73.8581 77.525 95.1278Z" fill="#F4E305"/>
        <path d="M62.1203 95.127C62.1203 107.889 51.7748 118.235 39.0128 118.235C26.2508 118.235 15.9053 107.889 15.9053 95.127C15.9053 82.365 26.2508 72.0195 39.0128 72.0195C51.7748 72.0195 62.1203 82.365 62.1203 95.127Z" fill="#BDAD05"/>
        <path d="M52.0586 100.117V194.461L100.882 176.606L149.706 194.461V100.117H52.0586Z" fill="#2B9AC9"/>
        <path d="M167.832 66.9498C167.832 103.925 137.857 133.9 100.882 133.9C63.9074 133.9 33.9326 103.925 33.9326 66.9498C33.9326 29.9748 63.9074 0 100.882 0C137.857 0 167.832 29.9748 167.832 66.9498Z" fill="#F4E305"/>
        <path d="M141.053 66.9492C141.053 89.1345 123.068 107.119 100.883 107.119C78.6974 107.119 60.7129 89.1345 60.7129 66.9492C60.7129 44.7638 78.6974 26.7793 100.883 26.7793C123.068 26.7793 141.053 44.7638 141.053 66.9492Z" fill="#BDAD05"/>
    </svg>
);

export default MultiRewardIcon;
