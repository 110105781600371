import React from 'react';

const FormTextArea = ({ id, name, type, label, inputRef = null, onChange = null, value = '', className = '', props = {} }) => {
    return (
        <div className={`${className}`}>
            <label htmlFor={id} className='block font-medium leading-tight mb-1'>{label}</label>
            <textarea id={id} type={type} name={name} className='w-full rounded-2xl px-6 py-3 border'
                defaultValue={value}
                onChange={onChange}
                ref={inputRef} {...props}></textarea>
        </div>
    );
}

export default FormTextArea;