import React from 'react';

const UserAvatarIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19 0.819336C8.5066 0.819336 0 9.32594 0 19.8193C0 30.3127 8.5066 38.8193 19 38.8193C29.4934 38.8193 38 30.3127 38 19.8193C38 9.32594 29.4934 0.819336 19 0.819336Z" fill="#59007A"/>
        <path d="M28.2395 32.5641C25.5698 34.5081 22.3657 35.5586 18.9996 35.5586C15.5491 35.5586 12.2686 34.455 9.55957 32.416L4.97461 35.9302C8.72705 39.1996 13.632 41.1798 18.9996 41.1798C24.3104 41.1798 29.168 39.2411 32.9047 36.0337L28.2395 32.5641Z" fill="#98FD1E"/>
        <path d="M24.6217 19.8197C24.6217 22.9193 22.1 25.4411 19.0004 25.4411C15.9008 25.4411 13.3791 22.9193 13.3791 19.8197H24.6217ZM19.0004 8.57715C12.7912 8.57715 7.75781 13.6107 7.75781 19.8197C7.75781 26.0288 12.7912 31.0624 19.0004 31.0624C25.2096 31.0624 30.243 26.0288 30.243 19.8197C30.243 13.6107 25.2096 8.57715 19.0004 8.57715Z" fill="white"/>
    </svg>
);

export default UserAvatarIcon;
