import React from 'react';

const SurroundingsIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 100 118" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.4495 67.7012C52.4495 82.1848 40.7084 93.9259 26.2247 93.9259C11.7412 93.9259 0 82.1848 0 67.7012C0 53.2177 11.7412 41.4766 26.2247 41.4766C40.7084 41.4766 52.4495 53.2177 52.4495 67.7012Z" fill="#9FF912"/>
        <rect x="23" y="82.2383" width="6" height="35" fill="#77BB0E"/>
        <rect x="61" y="82.2383" width="12" height="35" fill="#9FF912"/>
        <path d="M34.9668 93.9269L67.0192 0.683594L99.0715 93.9269H34.9668Z" fill="#77BB0E"/>
    </svg>
);

export default SurroundingsIcon;