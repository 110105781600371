import React, {useState} from 'react';

import requestClient from '../../utils/requestClient';
import { BookmarkIcon } from '../Icons/';

const FavouriteButton = ({ article, favourited }) => {
    const [isFavourited, setIsFavourited] = useState(favourited);

    async function addFavourite() {
        const response = await requestClient().get(`/api/v2/favourites/add/${article.content_type}/${article.id}/`)
        setIsFavourited(true)
    }

    async function removeFavourite() {
        const response = await requestClient().get(`/api/v2/favourites/remove/${article.content_type}/${article.id}/`)
        setIsFavourited(false)
    }

    return (
        <button onClick={() => isFavourited ? removeFavourite() : addFavourite()} className='btn btn-invert flex'>
            <BookmarkIcon />
            <span className="ml-2 hidden lg:block">{isFavourited ? 'Remove from favourites' : 'Add to favourites'}</span>
        </button>
    );
};

export default FavouriteButton;


