import React from 'react';
import { Link } from 'react-router-dom';

import { resourceUrl } from '../../../utils/resourceMapper';
import Image from '../../../components/Elements/Image';

const ArticleCardWorkshop = ({ item, selectedGoal, callback }) => {
    const url = resourceUrl(item)
    let imageUrl = 'https://place-hold.it/300x200'

    if ('thumbnail' in item && item.thumbnail) {
        imageUrl = item.thumbnail
    }
    else if ('image' in item && item.image) {
        imageUrl = item.image
    }

    return (
        <div className='lg:flex lg:flex-col'>
            <Link to={url}>
                <Image
                    src={imageUrl} alt={item.title} className="w-full rounded-xl lg:rounded-2xl aspect-video object-cover"
                />
            </Link>
            <div className='mt-4 mb-6 lg:flex lg:grow lg:flex-col'>
                <Link to={url}>
                    <h3 className="h4 mb-4">{item.title}</h3>
                    <p className="text-sm leading-none whitespace-pre-line">{item.description}</p>
                </Link>
            </div>
            <div className='flex gap-2 flex-col'>
                <Link to={url} className="btn btn-invert">
                    View
                </Link>
                {selectedGoal === item.id ? <span className="btn bg-grey text-[#807C81]">Selected</span> : <button onClick={() => callback(item.id)} className="btn btn-purple">
                    Select
                </button>}
            </div>
        </div>
    );
};

export default ArticleCardWorkshop;