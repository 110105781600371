import React from 'react';

const BodyIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.4649 4.99995C20.4649 2.23848 18.2265 0 15.465 0C12.7035 0 10.4648 2.23848 10.4648 4.99995C10.4648 7.76142 12.7035 9.9999 15.465 9.9999C18.2265 9.9999 20.4649 7.76142 20.4649 4.99995Z" fill="white"/>
        <path d="M20.4649 25.8569V25V16.3487C23.451 18.0785 25.4648 21.3008 25.4648 25H30.4648C30.4648 16.7156 23.7492 10 15.4649 10C7.25535 10 0.590115 16.5963 0.470552 24.7773C0.469575 24.8518 0.464886 24.9254 0.464886 25H0.468598H5.46484C5.46484 24.9248 5.47461 24.8522 5.47617 24.7773C5.55568 21.1723 7.53941 18.0433 10.4648 16.3487V24.7773V25V25.8118C9.93535 30.8477 5.59261 34.7772 0.303711 34.7772V39.7774C6.75111 39.7774 12.2469 35.7089 14.3678 30H15.4649C20.9879 30 25.4648 34.4772 25.4648 40.0001H30.4648C30.4648 33.4695 26.2903 27.9164 20.4649 25.8569Z" fill="#1E214F"/>
    </svg>

);

export default BodyIconResults;

