import React from 'react';

const MoneyIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 94 82" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M52.4666 0H41.5332C18.8896 0 0.533203 18.3565 0.533203 41C0.533203 63.6435 18.8896 82 41.5332 82H52.4666C75.1099 82 93.4663 63.6435 93.4663 41C93.4663 18.3565 75.1099 0 52.4666 0Z" fill="#1D2151"/>
        <path d="M82.533 41C82.533 63.6435 64.1765 82 41.5332 82C18.8896 82 0.533203 63.6435 0.533203 41C0.533203 18.3565 18.8896 0 41.5332 0C64.1765 0 82.533 18.3565 82.533 41Z" fill="#2B9AC9"/>
        <path d="M66.1333 41.0004C66.1333 54.5867 55.1196 65.6004 41.5334 65.6004C27.9473 65.6004 16.9336 54.5867 16.9336 41.0004C16.9336 27.4141 27.9473 16.4004 41.5334 16.4004C55.1196 16.4004 66.1333 27.4141 66.1333 41.0004Z" fill="#1D2151"/>
    </svg>
);

export default MoneyIcon;
