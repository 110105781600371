import React from 'react';

const CompetitionIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 251 225" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="83.833" y="141.666" width="83.3333" height="83.3333" fill="#4A0D6F"/>
        <rect x="167.167" y="166.379" width="83.3333" height="58.6207" fill="#926EA9"/>
        <rect x="0.5" y="183.619" width="83.3333" height="41.3793" fill="#926EA9"/>
        <path d="M137.654 207.539L125.5 203.094L113.346 207.539V184.053H137.654V207.539Z" fill="#2B9AC9"/>
        <path d="M142.166 175.794C142.166 184.999 134.705 192.46 125.5 192.46C116.295 192.46 108.833 184.999 108.833 175.794C108.833 166.589 116.295 159.127 125.5 159.127C134.705 159.127 142.166 166.589 142.166 175.794Z" fill="#F4E305"/>
        <path d="M135.5 175.795C135.5 181.317 131.023 185.795 125.5 185.795C119.978 185.795 115.5 181.317 115.5 175.795C115.5 170.272 119.978 165.795 125.5 165.795C131.023 165.795 135.5 170.272 135.5 175.795Z" fill="#BDAD05"/>
        <circle cx="201" cy="88" r="4" fill="#59007A"/>
        <circle opacity="0.6" cx="157" cy="45" r="4" fill="#59007A"/>
        <circle opacity="0.3" cx="96" cy="49" r="4" fill="#59007A"/>
        <circle opacity="0.1" cx="104" cy="118" r="4" fill="#59007A"/>
        <circle opacity="0.1" cx="92" cy="10" r="4" fill="#59007A"/>
        <circle opacity="0.1" cx="220" cy="41" r="4" fill="#59007A"/>
        <circle opacity="0.1" cx="31" cy="154" r="4" fill="#59007A"/>
        <circle opacity="0.3" cx="35" cy="18" r="4" fill="#59007A"/>
        <circle opacity="0.3" cx="205" cy="138" r="4" fill="#59007A"/>
        <circle opacity="0.3" cx="140" cy="98" r="4" fill="#59007A"/>
        <circle opacity="0.3" cx="187" cy="4" r="4" fill="#59007A"/>
        <circle cx="47" cy="118" r="4" fill="#59007A"/>
    </svg>
);

export default CompetitionIcon;
