import React from 'react';
import { Link } from 'react-router-dom';

import ArticleCard from '../../components/Elements/Cards/ArticleCard';

const RelatedArticles = ({ article }) => {
    return (
        <section>
            <div className='container py-8 lg:py-12'>
                <div className='max-w-[768px] mx-auto'>
                    <div className='flex items-center justify-between mb-8 gap-4 flex-col lg:flex-row'>
                        <h2>Next Read This</h2>
                        <Link to={`/resources/${article.content.content_type}/`} className='btn btn-invert w-full inline-block lg:w-auto'>
                            View All
                        </Link>
                    </div>
                    <div className='overflow-hidden'>
                        <div className='scroll-bar grid grid-flow-col auto-cols-full lg:auto-cols-1/2 gap-8 pb-6'>
                            {article.related_content.map(item => (
                                <ArticleCard key={item.id} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RelatedArticles;