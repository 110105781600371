import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import Histogram from '../../components/Elements/Reporting/Histogram';

const Results = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getData() {
            const response = await requestClient().get(`/api/v2/self-reflection/?month=1`)
            setData(response.data.data)
        }
        getData();
    }, []);

    return (data ?
        <main>
            <section>
                <div className='container py-8 lg:py-12 max-w-[768px]'>
                    <div className='mb-8'>
                        <h1 className='mb-4'>Congratulations!</h1>
                        <div className='prose'>
                            <p>You have completed your monthly checkpoint questions. All answers are confidential and will allow us to better recommend areas to focus on and what actions you can take.</p>
                        </div>
                    </div>
                    <div className="col-span-12 bg-white p-8 lg:col-span-8 mb-8">   
                        <h3 className='mb-6'>Your Results</h3>
                        <Histogram histogramData={data} className={'lg:grid-cols-2'} />
                    </div>
                    <div>
                        <div className='prose mb-8'>
                            <p>Remember: The aim is not to achieve a score of 10/10 but to know where you are right now and the areas you can focus on at this moment in time.</p>
                        </div>
                        <Link to="/" className="btn btn-purple w-full flex justify-center lg:ml-auto lg:max-w-[250px]">NEXT</Link>
                    </div>
                </div>
            </section>
         </main>
    : null);
};

export default Results;
