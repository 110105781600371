
import React from 'react';

const DownArrowIcon = ({ className, style }) => (
    <svg className={className} style={style} width="21" height="11" viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 11L0.540707 0.5H20.4593L10.5 11Z" fill="#EC4A18"/>
    </svg>
);

export default DownArrowIcon;
