import React from 'react';

const MoneyIconResults = ({ className, style }) => (
    <svg className={className} style={style} width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7723 0.353516H18.0664C27.8125 0.353516 35.7135 8.25446 35.7135 18.0006C35.7135 27.7467 27.8125 35.6476 18.0664 35.6476H22.7723C32.5184 35.6476 40.4193 27.7467 40.4193 18.0006C40.4193 8.25446 32.5184 0.353516 22.7723 0.353516Z" fill="#1D2151"/>
        <path d="M35.714 18.0006C35.714 8.25446 27.8131 0.353516 18.067 0.353516C16.5442 0.353516 15.0663 0.546457 13.6566 0.909045C11.6832 1.41681 9.84322 2.25752 8.20039 3.3674C7.73098 3.68457 7.27757 4.02363 6.8418 4.38316C6.40604 4.74293 5.98792 5.12316 5.58863 5.52222C5.18957 5.92152 4.80933 6.33963 4.44957 6.7754C4.09004 7.21116 3.75098 7.66457 3.4338 8.13399C2.32392 9.77681 1.48322 11.6168 0.975451 13.5902C0.612863 14.9999 0.419922 16.4778 0.419922 18.0006C0.419922 19.5234 0.612863 21.0013 0.975451 22.4109C1.29027 23.634 1.73686 24.8036 2.29216 25.909C2.63263 26.5872 3.01169 27.2427 3.4338 27.8672C3.75098 28.3366 4.09004 28.79 4.44957 29.2258C4.80933 29.6615 5.18957 30.0799 5.58863 30.4789C5.98792 30.8782 6.40604 31.2582 6.8418 31.618C7.27757 31.9775 7.73098 32.3168 8.20039 32.6338C8.82486 33.0559 9.48039 33.4349 10.1585 33.7754C11.2639 34.3307 12.4336 34.7773 13.6566 35.0921C15.0663 35.4547 16.5442 35.6476 18.067 35.6476C27.8131 35.6476 35.714 27.7467 35.714 18.0006ZM18.067 28.5888C12.2192 28.5888 7.47875 23.8483 7.47875 18.0006C7.47875 12.1528 12.2192 7.41234 18.067 7.41234C23.9147 7.41234 28.6552 12.1528 28.6552 18.0006C28.6552 23.8483 23.9147 28.5888 18.067 28.5888Z" fill="white"/>
        <path d="M18.0667 7.41211C12.219 7.41211 7.47852 12.1526 7.47852 18.0003C7.47852 23.8481 12.219 28.5886 18.0667 28.5886C23.9145 28.5886 28.655 23.8481 28.655 18.0003C28.655 12.1526 23.9145 7.41211 18.0667 7.41211Z" fill="#1D2151"/>
    </svg>
);

export default MoneyIconResults;


