import React from 'react';
import { LogoSpinner } from '../Icons';

const Loader = ({ text, className = '' }) => {
    

    return (
        <div className={`text-center ${className}`}>
            <LogoSpinner className="mx-auto mb-4 transition-all loading" />
            <p>{text}...</p>
        </div>
    );
};

export default Loader;


