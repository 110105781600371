import React, { useEffect, useState, useRef } from 'react';

import requestClient from '../../utils/requestClient';

import FormInput from '../../components/Elements/Form/FormInput';
import SuccessMessage from '../../components/Elements/SuccessMessage';
import ErrorMessage from '../../components/Elements/ErrorMessage';

const Settings = ({ }) => {
    const [company, setCompany] = useState(null);
    const [success, setSuccess] = useState(false);
    const [errors, setErrors] = useState(null);

    const companyNameRef = useRef(null)

    const [logo, setLogo] = useState(null);

    useEffect(() => {
        async function getCompany() {
            const response = await requestClient().get('/api/v2/employer/company/')

            setCompany(response.data.data);
        }
        getCompany();
    }, []);

    const fileChanged = (event) => {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            setLogo(reader.result)
        } 
    }

    async function handleSubmit(event) {
        event.preventDefault()
        setSuccess(false)
        setErrors(null)

        await requestClient().patch('/api/v2/employer/company/', {
            company_name: companyNameRef.current.value,
            logo: logo
        }).then(response => {
            if(response.status === 200){
                setSuccess(true)
                setCompany(response.data.data);
            }
        })
        .catch(error => {
            setErrors(error.response.data.error)
        })
    }

    return (company ?
        <main>
            <section className="bg-white">
                <div className='container py-8 lg:py-12'>
                    <div className='flex items-center justify-between border-b pb-8 mb-8'>
                        <h1>Profile Settings</h1>
                    </div>
                    {success ? <SuccessMessage title="Profile updated" text="Your profile has been updated" className="mb-4 sticky top-2" /> : null}
                    {errors ? <ErrorMessage title="Profile not updated" errors={errors} className="mb-4 sticky top-2" /> : null}
                    <form onSubmit={handleSubmit}>
                        <div className='grid gap-8 lg:gap-12 lg:grid-cols-12 mb-8'>
                            <div className='lg:col-span-6'>
                                <h2 className='h3 mb-4'>Company Details</h2>
                                <div className='grid gap-6'>
                                    <div>
                                        {company.logo ? (<img src={company.logo} className='h-[100px] w-[100px] object-fit rounded-full mb-2' />) : null }
                                        <label className='block font-medium leading-tight mb-1'>Logo:</label>
                                        <input type="file" name="logo" label="Logo" onChange={fileChanged} />
                                    </div>
                                    <FormInput type="text" name="company_name" label="Name:" value={company.name} inputRef={companyNameRef} />
                                </div>
                             </div>
                        </div>
                        <button className="btn btn-purple block lg:inline-block lg:min-w-[250px]">UPDATE</button> 
                    </form>
                </div>
            </section>
        </main>
    : null);
};

export default Settings;
