import React from 'react';

const CrossIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 18.638 18.636">
        <g id="Group_228" data-name="Group 228" transform="translate(-309.362 -43.749)">
            <path id="Path_393" data-name="Path 393" d="M-13799.284-21749.9l17.929,17.928" transform="translate(14109 21794)" fill="none" stroke="#030f27" stroke-width="1"/>
            <path id="Path_394" data-name="Path 394" d="M-13799.284-21749.9l17.929,17.928" transform="translate(-21422.25 13843.387) rotate(90)" fill="none" stroke="#030f27" stroke-width="1"/>
        </g>
    </svg>
);

export default CrossIcon;
