import React from 'react';

import {
    Routes, Route, Navigate
} from 'react-router-dom';

import BaseLayout from '../components/Layout/BaseLayout';
import Login from '../pages/Authentication/Login';
import ResetPassword from '../pages/Authentication/ResetPassword';
import NewPassword from '../pages/Authentication/NewPassword';
import RegisterAccountFlow from '../pages/Register/Flow';

const EmployeeRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<BaseLayout />} >
                <Route path="/" element={<Navigate to="/login" replace />} />
                <Route path="login" element={<Login />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="reset/:id/:token" element={<NewPassword />} />
                <Route path="register/:id/:token/*" element={<RegisterAccountFlow />} />
                <Route path="*" element={<Navigate to={`/login?next=${window.location.pathname}`} />} />
            </Route>
        </Routes>
    );
};

export default EmployeeRoutes;