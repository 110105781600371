import React from 'react';
import { Link } from 'react-router-dom';

import ArticleCard from '../../components/Elements/Cards/ArticleCard';

const ArticleLists = ({ data, articlesPerRow = 3 }) => {
    return (
        Object.keys(data).map((type, index) => {
            return (
                <React.Fragment key={type}>
                    {(data[type].articles.length > 0) ? (
                        <div className={`grid gap-8 lg:grid-cols-4 py-8 ${index !== (Object.keys(data).length - 1) ? 'border-b' : ''}`}>
                            <div className='lg:col-span-1'>
                                <h3>{data[type].title}</h3>
                                { data[type].link ? (<Link to={data[type].link} className="btn btn-invert mt-4 block lg:inline-block">View All</Link>) : null }
                            </div>
                            <div className='lg:col-span-3 overflow-hidden'>
                                <div className={`scroll-bar grid grid-flow-col auto-cols-full lg:auto-cols-1/${articlesPerRow} gap-8 pb-6`}>
                                    {data[type].articles.map((article, index) => (
                                        <ArticleCard key={index} item={article} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ) : null}
                </React.Fragment>
            );
        })
    );
};

export default ArticleLists;