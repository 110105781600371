import React from 'react';
import  { useNavigate } from 'react-router-dom'

import requestClient from '../../utils/requestClient';
import { CompetitionIcon } from '../../components/Icons';

const PageFive = () => {
    const navigate = useNavigate();

    async function handleSubmit(event) {
        event.preventDefault()

        const response = await requestClient().patch('/api/v2/user/', {
            completed_onboarding: true
        })

        navigate('/dashboard')
    }

    return (
        <main>
            <section>
                <div className='container py-8 lg:py-12'>
                    <div className='grid gap-8 lg:grid-cols-2'>
                        <div className='lg:order-2 self-center'>
                            <CompetitionIcon 
                                className="lg:mx-auto w-full max-w-[100px] lg:max-w-[250px]"
                            />
                        </div>
                        <div>
                            <h1 className="mb-4">Competitions</h1>
                            <div className='prose mb-6'>
                                <p>
                                 Would you like to win one of our competition prizes? 
                                    Trade your points and enter today! 
                                    You will then receive an email notification confirming your entry date and the winner will be informed.   
                                </p>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <button className="btn btn-purple block lg:inline-block lg:min-w-[250px]">FINISH</button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
};

export default PageFive;
