import React from 'react';

const Video = ({ src, className = null }) => {
    const assetDomain = process.env.REACT_APP_ASSET_DOMAIN 

    return (
        <video src={src.includes('http') ? src : assetDomain + src} controls className={className} />
    );
};

export default Video;
