import React from 'react';

const ProgressBar = ({ progress, className = '' }) => {
    return (
        <div className={`w-full bg-white rounded-full h-2.5 ${className}`}>
            <div className="bg-purple h-2.5 rounded-full transition-all" style={{ width: `${progress}%` }}></div>
        </div>
    );
};

export default ProgressBar;
