import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Footer from '../Footer';
import NavBar from '../NavBar';
import ScoreBar from '../ScoreBar';

const MainLayout = ({links, banner = false}) => {
        const location = useLocation();
        const isDashboard = [
            '/dash', '/dashboard', '/home'
        ].includes(location.pathname);

    return (
        <div>    
            <NavBar links={links} banner={banner}/>

            <ScoreBar className={isDashboard ? "block" : "hidden lg:block"} />

            <Outlet />
            
            <Footer />
        </div>
    );
}

export default MainLayout;
