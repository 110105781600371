import React, { useContext, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import requestClient from '../../utils/requestClient';
import { resourceMapper } from '../../utils/resourceMapper';
import Image from '../../components/Elements/Image';
import Video from '../../components/Elements/Video';
import FavouriteButton from '../../components/Elements/FavouriteButton';
import RatingButton from '../../components/Elements/RatingButton';
import ArticleBreadcrumb from '../../components/Elements/ArticleBreadcrumb';
import FormCheckbox from '../../components/Elements/Form/FormCheckbox';
import RelatedArticles from '../../components/Sections/RelatedArticles';
import { CurrentUser } from '../../App';
import SuccessMessage from '../../components/Elements/SuccessMessage';

const Article = () => {
  const user = useContext(CurrentUser)
  const { type, id } = useParams();
  const resource = resourceMapper(type)

  const [article, setArticle] = useState([]);
  const [tasks, setTasks] = useState(null);
  const [complete, setComplete] = useState(user.user.active_goal ? user.user.active_goal.is_complete : false);

  useEffect(() => {
    async function getArticle() {
      const response = await requestClient().get(`/api/v2/${type}/${id}`)

      if (user.user.active_goal && user.user.active_goal.goal.video.id === response.data.data.content.id) {
        let tasks = {}

        user.user.active_goal.tasks.forEach(task => {
          tasks[task.id] = task
        })

        setTasks(tasks)
      }

      setArticle(response.data.data);
    }
    getArticle();
  }, []);

  const updateTask = async (taskId, completed) => {
    const response = await requestClient().post(`/api/v2/goals/tasks/${taskId}/`, {
      is_complete: completed
    })

    let newTasks = { ...tasks };
    newTasks[taskId].is_complete = completed
    setTasks(newTasks)

    let isComplete = true
    Object.keys(tasks).forEach(key => {
      if (tasks[key].is_complete === false) {
        isComplete = false
      }
    })
    setComplete(isComplete)
  }

  return (article.content ?
    <main>
      <article className='bg-white'>
        <div className='container py-8 lg:py-12'>
          <ArticleBreadcrumb article={article.content} />

          <Link to={`/resources?type=${article.content.content_type}`} className="flex items-center mb-8 lg:hidden">← RESOURCES</Link>

          <div className='max-w-[768px] mx-auto'>
            <h1 className='mb-8'>{article.content.title}</h1>
            {article.content.video ? (
              <Video src={article.content.video} className="aspect-video w-full rounded-xl lg:rounded-2xl" />
            ) : (<Image src={article.content.image} className="aspect-video w-full rounded-xl lg:rounded-2xl" />)}

            {article.content.pdf_document ? (<a href={article.content.pdf_document} target='_blank' className="btn btn-purple mt-4 block lg:inline-block">Download Content</a>) : null}

            <section>
              {'description' in article.content ? (
                <div className="mt-8 prose whitespace-pre-line">
                  <p>{article.content.description}</p>
                </div>
              ) : null}

              {'content' in article.content ? (
                <div className="mt-8 prose whitespace-pre-line">
                  <p>{article.content.content}</p>
                </div>
              ) : null}
            </section>

            {tasks ?
              (<div className='border rounded-2xl p-8 mt-8 shadow-lg'>
                {complete ? <SuccessMessage title="Well done" text="You have completed this workshop!" className="mb-4" /> : null}
                <h3 className='h4 mb-6'>Tasks:</h3>
                <ul>
                  {Object.keys(tasks).map((taskId) => (
                    <li className="flex gap-4 justify-between mb-4" key={taskId}>
                      <FormCheckbox label={tasks[taskId].task.title} value={tasks[taskId].is_complete} onChange={(event) => { updateTask(taskId, !tasks[taskId].is_complete) }} />
                    </li>
                  ))}
                </ul>
                {complete ? <Link to="/workshops" className="mt-6 btn btn-invert block">SELECT ANOTHER WORKSHOP</Link> : null}
              </div>) : null
            }

            {resource.rating || resource.favourite ?
              <div className="mt-8 py-4 border-t border-b flex items-center justify-between">
                {resource.rating ? <RatingButton article={article.content} /> : null}
                {resource.favourite ? <FavouriteButton article={article.content} favourited={article.favourited} /> : null}
              </div> : null}
          </div>
        </div>
      </article>
      <RelatedArticles article={article} />
    </main>
    : null);
};

export default Article;
