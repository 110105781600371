import React from 'react';

const RelationshipsIcon = ({ className, style }) => (
    <svg className={className} style={style} viewBox="0 0 96 86" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M45.6004 7.82385C35.1686 -2.60795 18.2557 -2.60795 7.82385 7.82385C-2.60795 18.2557 -2.60795 35.1686 7.82385 45.6004L9.96998 47.7465L47.7465 85.5231V9.96998L45.6004 7.82385Z" fill="#F1948B"/>
        <path d="M87.6688 7.82385C77.237 -2.60795 60.324 -2.60795 49.8922 7.82385L47.7461 9.96998V85.5231L85.5226 47.7465L87.6688 45.6004C98.1006 35.1686 98.1006 18.2557 87.6688 7.82385Z" fill="#EC4A18"/>
    </svg> 
);

export default RelationshipsIcon;
