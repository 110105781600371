import axios from "axios";
import Cookies from 'js-cookie'

axios.defaults.withCredentials = true;
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";

const requestClient = () => {
    const csrftoken = document.getElementById('crsf').getAttribute('value')

    return axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: {
            'X-CSRFToken': csrftoken
        }
    })
}
export default requestClient